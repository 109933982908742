.global-header {
    @include respondto(sm) {
        padding-top: $padding-large-vertical;
    }

    .navbar-text {
        .btn-clean {
            border: 0;
            color: $dark-blue;
            font-size: $font-size-small;
            line-height: 1.1875;
            text-decoration: none;
        }
    }

    .mobile-navbar {
        border: 0;
        border-bottom: 3px solid $dark-blue
    }

    a {
        &:not(.btn) {
            &:visited {
                color: $dark-blue;
            }
        }
    }
}

#mobile-navbar-container {
    /*    background-blend-mode: multiply;
    background-image: url(../images/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 75%;*/
}

#mobile-nav-right {
    text-align: end;
}

#mobile-nav-right .logout-button {
    padding-right: 10px;
    padding-top: 24px;
}

.mobile-mypage-button {
    font-size: 16px;
    line-height: 1.1875;
}
