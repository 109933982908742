// Cookie-message
.cookie-message {
    background-color: $mercury;
    bottom: $padding-large-vertical;
    margin-bottom: 0;
    max-width: calc(100vw - (#{$padding-large-vertical} * 2));
    position: fixed;
    right: $padding-large-vertical;
    z-index: 1025;
    padding-left: 70px; 
    .close {
        font-size: inherit;
        margin: 0;
        opacity: 1;
        top: 2px;
        .icon {
            fill: $mine-shaft;
            transition: fill .3s ease-out;
        }
        @include link-interactive-styles {
            .icon {
                fill: $deep-cerulean;
            }
        }
    }
    .alert-link {
        font-weight: 700;
    }
}

.alert-cookie {
    p {
        @include respondto(md) {
            margin-bottom: 0;
        }
    }
}
