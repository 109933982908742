﻿/* archivo-regular - latin */
@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    src: url('/Static/Fonts/archivo-v8-latin/archivo-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Static/Fonts/archivo-v8-latin/archivo-v8-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* archivo-700 - latin */
@font-face {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    src: url('/Static/Fonts/archivo-v8-latin/archivo-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Static/Fonts/archivo-v8-latin/archivo-v8-latin-700.woff') format('woff'); /* Modern Browsers */
}
/* archivo-italic - latin */
@font-face {
    font-family: 'Archivo';
    font-style: italic;
    font-weight: 400;
    src: url('/Static/Fonts/archivo-v8-latin/archivo-v8-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Static/Fonts/archivo-v8-latin/archivo-v8-latin-italic.woff') format('woff'); /* Modern Browsers */
}

/* roboto-mono-regular - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 400;
    src: url('/Static/Fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Static/Fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* roboto-mono-700 - latin */
@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 700;
    src: url('/Static/Fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/Static/Fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-700.woff') format('woff'); /* Modern Browsers */
}
