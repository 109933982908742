.image-gallery {
    padding: 4.5rem 0 2rem 0;

    .image-container {
        border-top: 1px solid $mercury;
        border-bottom: 1px solid $mercury;
        padding: 2px 0;
    }

    .title {
        font-size: 1.8rem;
        font-weight: 600;
        color: $dark-blue;
        padding-top: 0.8rem;
    }

    .download-link {
        padding-top: 0.8rem;
    }
}
