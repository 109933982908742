﻿//
// Cards
// --------------------------------------------------

$enable-rounded: false;

//
// Base styles
//

.card {
	position: relative;
	margin-bottom: $card-spacer-y;
	border: $card-border-width solid $card-border-color;
	@include border-radius($card-border-radius);

	> :last-child {
		margin-bottom: $card-spacer-y / 2;
	}
}

.card-block {
	padding: $card-block-padding;
}

.card-block-x {
	padding-left: $card-spacer-x;
	padding-right: $card-spacer-x;
}

.card-block-y {
	padding-top: $card-spacer-x;
	padding-bottom: $card-spacer-x;
}

.card-title {
	&,
	&.h1,
	&.h2,
	&.h3,
	&.h4,
	&.h5 {
		margin-top: 0;
		margin-bottom: $card-spacer-y;
	}
}

.card-subtitle {
	margin-top: -($card-spacer-y / 2);
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

// .card-actions {
//   padding: $card-spacer-y $card-spacer-x;

//   .card-link + .card-link {
//     margin-left: $card-spacer-x;
//   }
// }

.card-link {
	@include hover {
		text-decoration: none;
	}

	+ .card-link {
		margin-left: $card-spacer-x;
	}
}

@if $enable-rounded {
	.card {
		> .list-group:first-child {
			.list-group-item:first-child {
				border-radius: $card-border-radius $card-border-radius 0 0;
			}
		}

		> .list-group:last-child {
			.list-group-item:last-child {
				border-radius: 0 0 $card-border-radius $card-border-radius;
			}
		}
	}
}


//
// Optional textual caps
//

.card-header {
	padding: $card-spacer-y $card-spacer-x;
	color: $card-cap-color;
	background-color: $card-cap-bg;
	border-bottom: $card-border-width solid $card-border-color;

	.card-title {
		margin: 0;
	}

	&:first-child {
		@include border-radius($card-border-radius-inner $card-border-radius-inner 0 0);
	}
}

.card-footer {
	padding: $card-spacer-y $card-spacer-x;
	color: $card-cap-color;
	background-color: $card-cap-bg;
	border-top: $card-border-width solid $card-border-color;

	&:last-child {
		@include border-radius(0 0 $card-border-radius-inner $card-border-radius-inner);
	}
}


//
// Background variations
//

.card-default {
	background: $card-default-background;
}

.card-primary {
	background-color: $brand-primary;
	border-color: $brand-primary;
}

.card-secondary {
	color: $text-color;
	background-color: $brand-secondary;
	border-color: $brand-secondary;
}

.card-success {
	background-color: $brand-success;
	border-color: $brand-success;
}

.card-info {
	background-color: $brand-info;
	border-color: $brand-info;
}

.card-warning {
	background-color: $brand-warning;
	border-color: $brand-warning;
}

.card-danger {
	background-color: $brand-danger;
	border-color: $brand-danger;
}


//
// Inverse text within a card for use with dark backgrounds
//

.card-inverse {
	.card-header,
	.card-footer {
		border-bottom: .075rem solid rgba(255,255,255,.2);
	}

	.card-header,
	.card-footer,
	.card-title,
	.card-blockquote {
		color: #fff;
	}

	.card-link,
	.card-text,
	.card-blockquote > footer {
		color: rgba(255,255,255,.65);
	}

	.card-link {
		@include hover-focus {
			color: #fff;
		}
	}
}


//
// Blockquote
//

.card-blockquote {
	padding: 0;
	margin-bottom: 0;
	border-left: 0;
}

// Card image
.card-img {
	// margin: -1.325rem;
	@include border-radius(.25rem);
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem;
}



// Card image caps
.card-img-top {
	@include border-radius(.25rem .25rem 0 0);
}

.card-img-bottom {
	@include border-radius(0 0 .25rem .25rem);
}


//
// Card set
//
.card-deck {
	display: table;
	table-layout: fixed;
	border-spacing: 1.25rem 0;

	.card {
		display: table-cell;
		width: 1%;
		vertical-align: top;
	}
}

.card-deck-wrapper {
	margin-right: -1.25rem;
	margin-left: -1.25rem;
}


//
// Card groups
//

.card-group {
	display: table;
	width: 100%;
	table-layout: fixed;


	.card {
		display: table-cell;
		vertical-align: top;

		+ .card {
			margin-left: 0;
			border-left: 0;
		}
		// Handle rounded corners
		@if $enable-rounded {
			&:first-child {
				.card-img-top {
					border-top-right-radius: 0;
				}

				.card-img-bottom {
					border-bottom-right-radius: 0;
				}
			}

			&:last-child {
				.card-img-top {
					border-top-left-radius: 0;
				}

				.card-img-bottom {
					border-bottom-left-radius: 0;
				}
			}

			&:not(:first-child):not(:last-child) {
				border-radius: 0;

				.card-img-top,
				.card-img-bottom {
					border-radius: 0;
				}
			}
		}
	}
}


//
// Card
//

.card-columns {
	column-count: 3;
	column-gap: 1.25rem;

	.card {
		display: inline-block;
		width: 100%; // Don't let them exceed the column width
	}
}
