.news-list {
    margin-top: 0;
    .startpage-content & {
        margin-top: $margin-xl
    }
    .article-list-item {
		margin: $line-height-computed 0;
	}
}

.article-list-item-meta.news-tag {
    display: inline-block; 
    margin-top: $margin-xl; 
    @include respondto(md){
       font-size: $font-size-base;  
    }
}

time.news-date {
    display: block; 
    font-size: 14px; 
    @include respondto(md){
        font-size: $font-size-base;
    }
}

time.start-page-news-date {
    display: block;
    font-size: 14px;
    padding-top: 10px;

    @include respondto(md) {
        font-size: $font-size-small;
    }
}

time.news-list-date {
    display: inline; 
    font-weight: 700;
    margin-right: $margin-xs;   
    @include respondto(md){
        font-size: $font-size-small;
    }
}

