﻿//
// Utility classes
.main-header {
	font-size: 36px;
	font-weight: 700;
	line-height: 1.203125; 
	margin-bottom: $margin-base;
	margin-top: 0; 
	@include respondto(sm){
		font-size: 42px;
	}
	@include respondto(md){
		font-size: $article-heading-size;
		margin-top: $margin-large;
		margin-bottom: $margin-large;
	}
}

.category {
    background-color: transparent;
    font-family: $font-family-complementary;
    color: $dark-blue;
    font-size: $font-size-xsmall;
    line-height: 1.1875;

    @include respondto(md) {
        font-size: $font-size-small;
    }
}

.gradient-border-top {
	background-image: linear-gradient(270deg, #FFFFFF 0%, rgba(228, 228, 228, 0.8) 18.23%, #C4C4C4 49.17%, rgba(227, 227, 227, 0.8) 83.43%, rgba(255, 255, 255, 0) 100%);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: 100% 1px;
}

.gradient-border-bottom {
	background-image: linear-gradient(270deg, #FFFFFF 0%, rgba(228, 228, 228, 0.8) 18.23%, #C4C4C4 49.17%, rgba(227, 227, 227, 0.8) 83.43%, rgba(255, 255, 255, 0) 100%);
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: 100% 1px;
}

.text-linewrap {
    white-space: normal!important;
    word-break: normal!important;
}

.generic-form {
    margin-bottom: $margin-xl;

    legend {
        border-bottom: 0;
        font-size: $font-size-large;
        font-weight: 700;
        line-height: 1.35;
        margin-bottom: $margin-base;
        margin-top: $margin-xl
    }

    label {
        font-size: $font-size-xsmall;
        line-height: 1.1875;
        font-weight: 400;
        margin-bottom: 10px;

        @include respondto(sm) {
            white-space: nowrap;
        }
    }

    .radio,
    .checkbox {
        label {
            line-height: 27px
        }
    }

    .has-feedback {
        select + .form-control-feedback {
            display: none;
        }

        @include respondto(sm) {
            select.form-control {
                -moz-appearance: none;
                -webkit-appearance: none;
            }

            select + .form-control-feedback {
                display: block;
            }

            select + .form-control-feedback .icon {
                fill: $mine-shaft;
                height: 39px;
                position: relative;
                top: -4px;
                transform-origin: center center 0px; // IE needs origin to handle transformation properly
                transition: transform $animation-speed-default;
                width: 39px;
            }

            select:focus + .form-control-feedback .icon {
                transform: rotate(-180deg)
            }
        }
    }
}

// --------------------------------------------------
$sizeList: xs sm md lg;
$units: '-0' '-xsmall' '-small' '-base' '-large' '-xlarge';
$unitValues: 0 $margin-xs $margin-small $margin-base $margin-large $margin-xl;
//
// Margin helper classes
// m-[TargetViewPortSize]-[ApplyMarginsTo]-[MarginSize]
// --------------------------------------------------
@each $viewportSize in $sizeList {
	@include respondto($viewportSize) {
		@each $unit in $units {
			$i: index($units, $unit);
			.m-#{$viewportSize}-a#{$unit} {
				margin: nth($unitValues, $i) !important;
			}
			.m-#{$viewportSize}-t#{$unit} {
				margin-top: nth($unitValues, $i) !important;
			}
			.m-#{$viewportSize}-r#{$unit} {
				margin-right: nth($unitValues, $i) !important;
			}
			.m-#{$viewportSize}-b#{$unit} {
				margin-bottom: nth($unitValues, $i) !important;
			}
			.m-#{$viewportSize}-l#{$unit} {
				margin-left: nth($unitValues, $i) !important;
			}
			.m-#{$viewportSize}-x#{$unit} {
				margin-left: nth($unitValues, $i) !important;
				margin-right: nth($unitValues, $i) !important;
			}
			.m-#{$viewportSize}-y#{$unit} {
				margin-top: nth($unitValues, $i) !important;
				margin-bottom: nth($unitValues, $i) !important;
			}
		}
	}
}

// --------------------------------------------------
/*$sizeList: xs sm md lg;
$units: '-0' '-xsmall' '-small' '-base' '-large' '-xlarge';
$unitValues: 0 $margin-xs $margin-small $margin-base $margin-large $margin-xl;*/
//
// Margin helper classes
// m-[TargetViewPortSize]-[ApplyMarginsTo]-[MarginSize]
// --------------------------------------------------
@each $viewportSize in $sizeList {
    @include respondto($viewportSize) {
        @each $unit in $units {
            $i: index($units, $unit);

            .p-#{$viewportSize}-a#{$unit} {
                padding: nth($unitValues, $i) !important;
            }

            .p-#{$viewportSize}-t#{$unit} {
                padding-top: nth($unitValues, $i) !important;
            }

            .p-#{$viewportSize}-r#{$unit} {
                padding-right: nth($unitValues, $i) !important;
            }

            .p-#{$viewportSize}-b#{$unit} {
                padding-bottom: nth($unitValues, $i) !important;
            }

            .p-#{$viewportSize}-l#{$unit} {
                padding-left: nth($unitValues, $i) !important;
            }

            .p-#{$viewportSize}-x#{$unit} {
                padding-left: nth($unitValues, $i) !important;
                padding-right: nth($unitValues, $i) !important;
            }

            .p-#{$viewportSize}-y#{$unit} {
                padding-top: nth($unitValues, $i) !important;
                padding-bottom: nth($unitValues, $i) !important;
            }
        }
    }
}
// --------------------------------------------------
// Width helper classes
// w-[Width in percent]
// --------------------------------------------------
$widthList: 25 50 75 100;

@each $unit in $widthList {
    .w-#{$unit} {
        width: percentage($unit/100);
    }
}


/* Centers the element based on the widts of the contents */

.center-content {
	width: intrinsic;
	/* For Safari, see https://developer.mozilla.org/en-US/docs/CSS/width */
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	display: table;
	margin-left: auto;
	margin-right: auto;
}

.border-0 {
    border: 0 !important;
}

.border-dark {
    border-color: $text-color !important;
}

.inline-block {
    display: inline-block;
}

.d-flex {
   display: flex !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}