.accordion-block {
  display: flex;
  flex-direction: column;

  &__toggle {
    display: none;

    &:checked + .accordion-block__header .accordion-block__icon {
      &::before {
        height: 0;
        top: 50%;
      }
    }

    &:checked + .accordion-block__header + .accordion-block__content {
      max-height: 1000rem;
      opacity: 1;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    color: $dark-blue;
    width: 100%;
    margin-bottom: $spacing-xxxs;
    padding-bottom: $spacing-xxxs;
    border-bottom: 1px solid $black;

    h2 {
      margin: 0;
    }
  }

  &__area-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    position: relative;
    width: 20px;
    height: 20px;
  }

  &__icon {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    transition: all 0.3s ease-in-out;

    &::before {
      content: '';
      width: 3px;
      height: 100%;
      background: $black;
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      transition: height 0.3s ease-in-out, top 0.3s ease-in-out;
    }

    &::after {
      content: '';
      width: 100%;
      height: 3px;
      background: $black;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;

    p {
      max-width: 72rem;
    }

    a, a:not(span):visited {
      color: $dark-blue;
      border-bottom: 1px solid $dark-blue;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    ul li {
      display: flex;
      align-items: center;
    }

    ul li::before {
      content: '•';
      margin-right: 0.5rem;
      display: inline-block;
      font-size: $font-size-large;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  // PersonListBlock

  .person-list-block {
    .vcard {
      &__title {
        font-size: clamp(18px, 1.5vw, 20px);
      }
    }
  }

  // RichTextBlock

  .rte-container.container {
    max-width: 72rem;
    margin: 0;

    .row {
      .richtextbox-block.container {
        max-width: 72rem;
        margin: 0;
        padding: 0;

        h4 {
          font-size: clamp(20px, 2vw, 24px);
          margin-bottom: $spacing-small;
        }
      }
    }
  }
}