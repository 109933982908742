// Icons
// Auto generated from settings.json at 8/23/2018, 11:16:23 AM

.icon { width: 16px; height: 16px; }
.icon-sm { width: 12px; height: 12px; }
.icon-md { width: 16px; height: 16px; }
.icon-lg { width: 24px; height: 24px; }
.icon-xl { width: 32px; height: 32px; }
.icon-xxl { width: 48px; height: 48px; }
.icon-white { fill: #fff; }
.icon-gray { fill: #444; }
