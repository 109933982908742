﻿.ribbon-animation {
    &:hover, &:focus {
        .ribbon {
            &:before, &:after {
                background-color: $diamond-blue;
            }
        }
    }
    &--author {
        &:hover, &:focus {
            .ribbon {
                &:before, &:after {
                    background-color: $yellow;
                }
            }
        }

        .ribbon {
            &:before, &:after {
                background-color: $yellow;
            }
        }
    }
}

.ribbon {
    &:before, &:after {
        content: "";
        display: block;
        position: absolute;
        background-color: $dark-blue;
        -webkit-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
        transform: rotate(50deg);
        width: 240px;
        height: 240px;
    }

    &:before {
        top: -233px;
        left: -110px;

        @include respondto(md) {
            top: -170px;
            left: -120px;
        }

        @include respondto(lg) {
            top: -135px;
            left: -106px;
        }
    }

    &:after {
        bottom: -220px;
        right: -120px;

        @include respondto(md) {
            bottom: -160px;
            right: -120px;
        }

        @include respondto(lg) {
            bottom: -135px;
            right: -110px;
        }
    }
}
