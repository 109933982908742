.top-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xxs 0;
  border-bottom: 1px solid $mercury;

  .top-navigation__to-start {
    position: relative;
    color: $dark-blue;
    border-bottom: 1px solid $dark-blue;

    a.link {
      color: $dark-blue;
      @include arrow-left($dark-blue, 2.2px, .35rem, .4rem);

      &:visited {
        color: $dark-blue;
        border-bottom: 1px solid $dark-blue;
      }

      &:hover {
        color: $link-hover-color;
      }
    }
  }

  &__progress-bar {
    flex: 1;
    text-align: center;
    margin-right: $spacing-small;
  }

  &__close {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid $dark-blue;

    a {
      position: relative;
      display: flex;
      align-items: center;
      color: $dark-blue;
      text-decoration: none;
      border-bottom: none;

      &::after {
        content: "\2715";
        margin-left: 8px;
        color: $dark-blue;
        font-weight: bold;
        transition: color 0.3s;

        @media (max-width: $screen-sm) {
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }
  }

  a {
    font-size: clamp(14px, 2vw, 20px);
    text-decoration: none;
    border-bottom: none;
    color: $dark-blue;

    &:not(span):visited {
      color: $dark-blue;
    }
  }
}