.my-handbook-startpage {
  margin-bottom: $spacing-xxl;
  @include new-font-sizes;
  
  a.link {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  &__heading {
    margin-bottom: $spacing-large;
  }

  &__text {
     margin-bottom: $spacing-small;
  }

  .my-handbook-courses {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: $spacing-medium;
    align-items: stretch;

    @media (max-width: $screen-md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    &__course {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      min-height: 20rem;
      background-color: $section-blue;
      padding: $spacing-small;
      box-sizing: border-box;

      @media (max-width: 576px) {
        min-height: auto;
      }

      h3 {
        margin-top: 0;
      }

      .arrows {
        margin-left: .3rem;
      }
    }

    .link {
      align-self: flex-end;
    }
  }
}