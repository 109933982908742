// Dropdown-menu
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.dropdown-menu {
    background-clip: unset;
        
        a {
        @include link-styles {
            border-bottom: 0
        }
    }
}

.header-menu {
    &.open {
        > .dropdown-menu {
            animation: fadeIn .5s;
            background-color: unset;
            border: unset;
            box-shadow: unset;
            padding: 0;
            transform-origin: top center;
            width: 100%;

            @include respondto(sm) {
                width: 750px;
            }
        }
    }

    .dropdown-menu-content {
        background-color: $diamond-blue;
        border: 1px solid $dark-blue;
        box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
        max-width: 1200px;
        padding: $padding-large-horizontal ($padding-small-horizontal * 2);
        transition: opacity $animation-speed-default ease-in-out;

        .menu-block {
            @include respondto(sm) {
                -webkit-columns: 3;
                -moz-columns: 3;
                columns: 3;
                -webkit-column-gap: 0;
                -moz-column-gap: 0;
                column-gap: 0;

                .dropdown-linklist {
                    -webkit-column-break-inside: avoid;
                    break-inside: avoid-column;
                    column-fill: balance;
                }
            }
        }
    }
}