// Page-contact
.page-contact {
    @include lightning-border-to-the-side;
    line-height: 1.222222222222222;
    padding: $padding-small-vertical ($font-size-base * $line-height-base) $padding-large-vertical;
    position: relative;

    @include respondto(xs-down){
       margin: $margin-xl 0;  
    }
}
