.show-more-btn {
  display: flex;
  align-items: center;
  margin-top: $spacing-medium;

  &__btn {
    background-color: $dark-blue;
    color: $white;
    padding: 10px;
    margin-right: $spacing-xs;

    &:hover {
      background-color: $link-hover-color;
      color: $white;
    }
  }

  &__result {
    font-size: $font-size-large;
  }
}