.startpage-content {
    .news-list-title {
        color: $dark-blue;
        font-size: $font-size-h1;
        font-weight: 700;
        line-height: 1.208333333333333
    }
}
.startpage-sidebar {
    margin-top: $margin-xl;

    .article-list {
        margin-top: 0; 
    }
}

.startpage-image {
	margin: $grid-gutter-width auto
}


.start-page-puff {

    &:first-child {
        margin-top: 0; 
    }
    .article-list-item-heading {
        font-size: $font-size-base; 
        color: $brand-primary; 

        @include respondto(md){
            font-size: $font-size-h2;  
        }
    }
}

.start-page-primary-puff {
    background-image: none;

    .startpage-image {
        margin: 15px auto;
    }

    .article-list-item-heading {
        font-size: 28px;
        margin-top: 5px;
        margin-bottom: 5px;

        @include respondto(md) {
            font-size: 36px;
        }

        @include respondto(md) {
            font-size: $font-size-h1;
        }
    }
}

.startpage-image {
    > .square-video {
        width: 100%;
    }
}