// Contact
.contact {
    p {
        margin: $margin-xl 0;
    }
}

.contact-information-link {
    display: inline-block; 
    margin-bottom: $margin-large; 
}