.bottom-navigation {
  a {
    border-bottom: 0;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $spacing-small;

    .btn-primary, .btn-primary:visited {
      color: $white;
    }

    .btn-white {
      outline: 1px solid $dark-blue;
    }
  }

  &__progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: $spacing-large;
    border-top: 1px solid $mercury;

    @media (max-width: $screen-sm) {
      padding-top: $spacing-xs;
    }

    .my-handbook-progress-bar {
      padding: 0;
    }
  }
}