.contact-region-text-block {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-small;
  margin-bottom: $spacing-small;
  
  h2 {
    margin-top: $margin-base;
    font-size: clamp(26px, 2.5vw, 32px);
  }

  h3 {
    font-size: clamp(22px, 2vw, 26px);
  }

  p {
    font-size: clamp(18px, 1.5vw, 20px);
  }

  .general-contact-information {
    max-width: 94rem;
    display: flex;
    flex-direction: row;
    gap: $spacing-medium;
    padding: $spacing-small;
    background-color: $section-blue;
    
    &__text {
      h3:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $screen-sm) {
      padding: $spacing-xs;
      flex-direction: column;
    }
  }

  .contact-students {
    display: flex;
    flex-direction: row;
    gap: $spacing-xl;
    align-items: flex-start;

    @media (max-width: $screen-sm) {
      flex-direction: column;
      gap: $spacing-xxs;
    }

    .contact-card {
      display: flex;
      flex-direction: column;
      gap: $spacing-xs;
      width: 100%;
      max-width: 35rem;
      height: auto;
      padding: $spacing-small $spacing-xs;
      background-color: $golden;

      @media (max-width: $screen-sm) {
        gap: $spacing-xxxs;
      }

      &__name {
        color: $black-blue;
        margin: 0;
      }

      &__title {
        @include detailed-text;
        color: $dark-blue;
      }

      &__email {
        @include detailed-text;
        word-break: break-word;
        display: flex;
        align-items: center;
        gap: $spacing-xxxs;
        text-align: left;
      }
    }

    &__contact-region {
      display: flex;
      flex-direction: column;
      gap: $spacing-xxxs;
    }
  }
}
