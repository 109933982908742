// Colors

// Colors from new graphic profile
$black-blue: #000363;
$dark-blue: #000596;
$clear-blue: #373cff;
$sky-blue: #37a0f0;
$diamond-blue: #78c8ff;
$section-blue: #d2e7fd;
$white: #fff;

$yellow: #f5b900;
$golden: #FADC80;
$coral: #ff6464;
$orange: #ff6400;


// Auto generated from settings.json at 8/23/2018, 11:16:23 AM

$mine-shaft:			#3D3D3D; // Mine Shaft
$wild-sand:			#F5F5F5; // Wild Sand
$mercury:			#E5E5E5; // Mercury

$regal-blue:			#004A6E; // Regal Blue
$prussian-blue:			#00354F; // Prussian Blue
$red-damask:			#DE6D36; // Red Damask
$deep-cerulean:			#0087A5; // Deep Cerulean
$avocado:			#39A11F;
$wattle:			#D4CD41; // Wattle
$gray:			#878787; // Gray
$alto:			#D1D1D1; // Alto
$silver-chalice:			#B2B2B2; // Silver Chalice
$cinnabar:			#D60000; // Cinnabar
$scorpion:			#5E5E5E; // Scorpion
$emperor:			#4f4f4f; // Emperor
$burnt-umber:			#933127; // Burnt Umber