// Icons
.icon {
	display: inline-block;
	fill: $brand-primary;
	vertical-align: middle;

	&.icon-white {
		fill: $white
	}
}

// TODO: Integrate this with icon settings

.icon-circle {
	position: relative;
	width: 16px;
	height: 16px;
	
	display: inline-block;
	padding: $margin-small;
	vertical-align: middle;
	
	background-color: $brand-primary;
	border-radius: 50%;

	&.icon-sm {
		width: 12px;
		height: 12px;
	}

	&.icon-lg {
		width: 24px;
		height: 24px;
	}

	&.icon-xl {
		width: 48px;
		height: 48px;
	}

	.icon {
		position: absolute;
		top: 25%;
		left: 25%;
		width: 50%;
		height: 50%;
	}

	svg {
		fill: #fff;
	}
}

// Icon handling à la ramverket, how we want them all to be, we want to kill the things above
// We'll rename icon-new to icon

.icon-new {
	@include icon-plate-size($icon-size-medium, $icon-size-medium);
	display: inherit;
	position: relative;

	&::before {
		@include icon($icon-size-medium);
	}

	&--email::before {
		@include icon-image(url("/Static/images/icons/email.svg"));
	}

	&--phone::before {
		@include icon-image(url("/Static/images/icons/phone.svg"));
	}

	&--user::before {
		@include icon-image(url("/Static/images/icons/user.svg"));
	}
}
