﻿#CybotCookiebotDialogPoweredbyCybot {
    display: none !important;
}

#CybotCookiebotDialogTabContent input[type=checkbox][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #1032cf !important;
}

.CybotCookiebotDialogDetailBodyContentCookieLink {
    display: none !important;
}