@mixin arrow-left($color, $arrow-size, $arrow-padding, $arrow-margin) {
  &::before {
    content: '';
    position: relative;
    top: .5rem;
    border: solid $color;
    border-width: 0 $arrow-size $arrow-size 0;
    display: inline-block;
    padding: $arrow-padding;
    transform: translateY(-50%) rotate(135deg);
    margin-right: $arrow-margin;
  }
}

@mixin arrow-right($color, $arrow-size, $arrow-padding, $arrow-margin) {
  &::after {
    content: '';
    position: relative;
    top: .5rem;
    border: solid $color;
    border-width: 0 $arrow-size $arrow-size 0;
    display: inline-block;
    padding: $arrow-padding;
    transform: translateY(-50%) rotate(315deg);
    margin-left: $arrow-margin;
  }
}