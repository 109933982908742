.start-page-links {
    background-color: $wild-sand;

    a {
        display: block;
        @include transition(background-color $animation-speed-default $animation-function-quintic);
        @include link-styles {
            border-bottom: 0
        }
        @include link-interactive-styles {
            background-color: $mercury
        }
        padding: $padding-large-vertical $padding-large-horizontal;
        @include respondto(sm) {
            padding-bottom: $padding-large-horizontal;
            padding-top: $padding-large-vertical;
        }
    }

    .h3 {

        @include respondto(xs-down) {
            margin-top: $margin-small; 
        }

    }

    p {
        color: $mine-shaft;
        font-size: $font-size-small;
        line-height: 1.375;
    }

}
