.my-handbook-module-section-page {
  @include new-font-sizes;

  position: relative;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  background-color: $section-blue;
  padding: $spacing-xl $spacing-xs $spacing-xl;

  @media (max-width: $screen-sm) {
    padding-top: $spacing-medium;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-large;
    max-width: 114rem;
    margin-right: auto;
    margin-left: auto;
    padding: $spacing-xxs $spacing-large $spacing-xl;
    background-color: $white;

    @media (max-width: $screen-sm) {
      padding: 0 $spacing-medium $spacing-small;
      gap: $spacing-small;
    }

    @media (max-width: $screen-xs) {
      padding: 0 $spacing-xxxs $spacing-small;
    }

    .link-collection {
      display: flex;
      flex-direction: column;
      gap: $spacing-xxs;
      padding: 0 $spacing-small $spacing-xl;

      @media (max-width: $screen-sm) {
        padding: 0 $spacing-xs $spacing-xl;
      }

      h3 {
        margin: 0;
      }

      li {
        list-style-type: none;

        a, a.link, a:not(span):visited:not(.btn) {
          color: $dark-blue;
          border-bottom: 1px solid $dark-blue;
        }
      }

    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: $spacing-large;
    padding: 0 $spacing-small $spacing-xl;

    @media (max-width: $screen-sm) {
      gap: $spacing-small;
      padding: $spacing-xxs $spacing-xxs $spacing-xl;
    }

    h1 {
      &.main-header {
        margin: 0;
      }
    }
  }

  a, a:not(span):visited:not(.btn) {
    color: $clear-blue;
    border-bottom: 1px solid $clear-blue;
  }
}