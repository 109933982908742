.hero-campaign {
    position: relative;
    
    background-color: $dark-blue;

    
}

.hero-campaign-row {
    display: flex;
}

.hero-campaign-text-col {
    padding-top: 60px;
    padding-bottom: 60px;

    @include respondto(sm) {
        padding-top: 80px;
        padding-bottom: 60px;
    }

    @include respondto(md) {
        padding-top: 110px;
        padding-bottom: 90px;
    }
}

.hero-campaign-anim-col {
    display: flex;
    align-items: end;
}

.hero-campaign-text-wrapper {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-10%);

    p:last-child {
        margin-bottom: 0;
    }
}

.hero-campaign-text {
    font-size: 38px;
    font-weight: $headings-font-weight;
    line-height: 1.3;
    color: $white;

    @include respondto(sm) {
        font-size: $font-size-h1;
    }

    @include respondto(md) {
        font-size: 80px;
    }
}

.hero-campaign-text-highlight {
    color: $orange;
}

.hero-campaign-footnote {
    font-size: $font-size-base;
    color: $white;
    font-weight: 400;
}

.hero-campaign-shape {
    * {
        animation-play-state: paused;
    }
}

.hero-campaign-shape-animate {
    * {
        animation-play-state: running;
    }
}

.hero-campaign-shape-thermometer {

    $line-anim-delay: 1s;
    $line-anim-dur: 3s;
    $circle-anim-delay: $line-anim-delay + $line-anim-dur / 2;
    $circle-anim-dur: .75s;
    $dot1-anim-delay: $circle-anim-delay + $circle-anim-dur;
    $dot1-anim-dur: 8s;
    $line-length: 380;

    height: 270px;
    width: auto;

    @include respondto(md) {
        height: auto;
        width: auto;
    }

    .line {
        stroke: $orange;
        stroke-dashoffset: $line-length;
        stroke-dasharray: $line-length;
        stroke-linecap: 'round';
        animation-delay: $line-anim-delay;
        animation-duration: $line-anim-dur;
        animation-name: line;
        animation-fill-mode: forwards;
    }

    .circle {
        stroke: $orange;
        transform-origin: 60px 30px; /* 2x size 1x size */
        transform: rotate(-90deg);
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        stroke-linecap: 'round';
        animation-delay: $circle-anim-delay;
        animation-duration: $circle-anim-dur;
        animation-name: circle;
        animation-fill-mode: forwards;
    }

    .dot1 {
        fill: $orange;
        animation-delay: $dot1-anim-delay;
        animation-duration: $dot1-anim-dur;
        animation-name: dot1;
        animation-fill-mode: forwards;
    }

    @keyframes line {
        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes circle {
        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes dot1 {
        to {
            transform: translateY(-200px);
        }
    }

}
