.statistics-block {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-xs;

  &__item {
    flex: 1 1 45%;
    box-sizing: border-box;
    padding-bottom: $spacing-large;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm) {
      flex: 1 1 100%;
    }

    &--odd, &--even {
      padding-bottom: 0;

      @media (max-width: $screen-sm) {
        padding-bottom: $spacing-large;
      }
    }

    &--even:last-child, &--odd:last-child {
      @media (max-width: $screen-sm) {
        padding-bottom: 0;
      }
    }
  }

  &__integer {
    font-size: $pf-font-size-xl;
    font-weight: $headings-font-weight;
    color: $clear-blue;
    display: flex;
    align-items: center;
    min-width: 10rem;

    @media (max-width: $screen-sm) {
      min-width: 8rem;
    }
  }

  &__text {
    font-size: $pf-font-size-medium;
    word-wrap: break-word;
    text-align: left;
    display: flex;
    align-items: center;
  }
}