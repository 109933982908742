﻿#off-canvas-container,
.off-canvas-active {
    overflow: hidden;
}

.off-canvas-right-active {
    overflow: hidden;
}

.off-canvas-backdrop {
    background-color: #00354F;
    display: none;
    height: calc((100%) - 54px);
    left: 0;
    opacity: 0.75;
    position: fixed;
    top: 54px;
    transition: opacity 0.25s ease-in-out;
    width: 100%;
    z-index: 999;
}

.off-canvas-backdrop:hover {
    opacity: 0.55;
}

.open .off-canvas-backdrop {
    display: block;
}

.off-canvas-menu {
    background-color: white;
    border-top: 3px solid #FEBE10;
    height: calc(100% - 51px);
    position: fixed;
    top: 51px;
    max-width: 75%;
    z-index: 1000;
    transition: transform 0.25s cubic-bezier(0.86, 0, 0.07, 1);
}

@media only screen and (min-width: 992px) {
    .off-canvas-menu {
        width: 420px;
    }
}

.off-canvas-menu .close {
    background: none;
    color: $yellow;
    cursor: pointer;
    font-size: inherit;
    opacity: 1;
    padding: 12px;
    pointer-events: none;
    position: absolute;
    top: 10px;
    z-index: 1;
}

.off-canvas-menu .close .icon {
    fill: $white;
}

.off-canvas-menu .dropdown-link {
    /*
		 * font-size * line-height = höjden på texten (minus den delen mellan botten av "baseline" och "bottom")
		 * padding-top + padding-bottom = ger höjden på boxen
		 * vi har inga marginaler att räkna på
		 * Slå ihop allt för att få hela höjden på boxen, dela på två för att få mitten, dra av höjden på strecket
		 */
    font-weight: 700;
    line-height: 1.1875;
    padding: 10px 12px;
}

.off-canvas-menu .dropdown-link::before, .off-canvas-menu .dropdown-link:link::before, .off-canvas-menu .dropdown-link:visited::before {
    height: 29px;
    left: 0;
    top: 0;
    -ms-transform: translate(0, 5px);
    transform: translate(0, 5px);
}

.open.off-canvas-menu {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: none;
}

.off-canvas-left {
    -ms-transform: translate(-340px, 0);
    transform: translate(-340px, 0);
    left: 0;
}

.off-canvas-left .close {
    left: 100%;
}

@media only screen and (min-width: 992px) {
    .off-canvas-left {
        -ms-transform: translate(-480px, 0);
        transform: translate(-480px, 0);
    }
}

.off-canvas-right {
    -ms-transform: translate(340px, 0);
    transform: translate(340px, 0);
    right: 0;
}

.off-canvas-right .close {
    right: 100%;
}

@media only screen and (min-width: 992px) {
    .off-canvas-right {
        -ms-transform: translate(480px, 0);
        transform: translate(480px, 0);
    }
}

.off-canvas-content {
    background-color: $diamond-blue;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    padding: 0;
    position: relative;
    /*hr*/
}

.off-canvas-content::-webkit-scrollbar {
    display: none;
}

.off-canvas-content .divider {
    border-color: $dark-blue;
    color: $dark-blue;
    margin: 0 24px 0 12px;
}

.off-canvas-content .function-menu {
    padding: 10px 12px;
}

.off-canvas-content .function-menu a {
    padding: 8px 0;
}

.off-canvas-content .function-menu a, .off-canvas-content .function-menu a:link, .off-canvas-content .function-menu a:visited {
    color: $dark-blue;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.1875;
}

.off-canvas-content .function-menu a:hover, .off-canvas-content .function-menu a:active, .off-canvas-content .function-menu a:focus {
    border-bottom: 1px solid #00354F;
    color: #00354F;
}

.no-csstransforms .off-canvas-left.off-canvas-menu {
    left: -480px;
}

.no-csstransforms .off-canvas-left.off-canvas-menu.open {
    left: 0;
}

.no-csstransforms .off-canvas-right.off-canvas-menu {
    right: -480px;
}

.no-csstransforms .off-canvas-right.off-canvas-menu.open {
    right: 0;
}
