// Contact-form
.contact-form {
    .contact {
        @extend .generic-form;
        padding-bottom: $margin-xl
    }

    .alert {
        margin-top: $margin-large
    }

    .already-a-member {
        margin: $margin-large 0;
    }
}