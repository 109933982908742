.attached-files-list {
    margin-bottom: 0; 
}

.attached-files-list-item {
   @extend .gradient-border-bottom;
   padding: $margin-base 0; 

   &:first-child {
       padding-top: 0; 
   }

   &:last-child {
       background: none; 
       padding-bottom: 0; 
   }

   a {
       border: none; 
   }

   span {
        border-bottom: 1px solid $dark-blue;
   }

   p {
       margin-bottom: 0; 
       //fortsätt här sen, uppdatera detta i projektet. 
   }
}

.attached-files-list-text {
    margin-bottom: 0; 
    padding-top: $margin-xs; 
}