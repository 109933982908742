.contact-information {
    padding-bottom: $margin-large;

    h1 {
        font-size: clamp(36px, 4vw, 48px);
    }

    h2 {
        margin-top: $margin-base;
        font-size: clamp(26px, 2.5vw, 32px);
    }

    h3 {
        font-size: clamp(22px, 2vw, 26px);
    }

    p {
        font-size: clamp(18px, 1.5vw, 20px);
    }

    .tel, .mail {
        margin-bottom: $margin-base;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: $spacing-xxxs;

        a {
            font-size: 2.4rem;
            line-height: 34px;
        }
    }
}