// Dropdown-link
$dropdown-link-line-width: 4px;

$top-offset: (($font-size-small * $line-height-small) - ($padding-large-vertical * 2)) * 0.25;

.dropdown-link {
    position: relative;
    display: block;
    padding: $padding-large-vertical $padding-large-horizontal;
    font-size: $font-size-small;
    line-height: $line-height-small;
    transition: color $animation-speed-default ease-in-out;

    @include link-styles() {
        border-bottom: 0;
        color: $dark-blue;
        @include border-to-the-side;
    }

    @include link-interactive-styles() {
        color: $link-hover-color;
        text-decoration: none;

        &::before {
            background-color: $yellow;
            left: 0;
        }
    }
}