.alert {
    font-family: $font-family-complementary;
}

.alert-error {
    position: relative;
    background-color: $yellow;
    padding-left: 70px;

    .alert-icon {
        .icon {
            fill: $text-color; 
        }
    }

    .close {
        font-size: inherit;
        margin: 0;
        opacity: 1;

        .icon {
            fill: $dark-blue;
            transition: fill .3s ease-out
        }

        &:hover, &:active, &:focus {
            .icon {
                fill: $link-hover-color;
            }
        }
    }
}

.alert-info {
    color: $dark-blue;
    position: relative;
    background-color: $diamond-blue;
    border: none;
    padding-left: 70px;

    .close {
        font-size: inherit;
        margin: 0;
        opacity: 1;

        .icon {
            fill: $dark-blue;
            transition: fill .3s ease-out
        }

        &:hover, &:active, &:focus {
            .icon {
                fill: $link-hover-color;
            }
        }
    }
}

.alert-cookie {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 999;
    margin-bottom: 0;
}

.alert-icon {
    position: absolute;
    width: 46px;
    height: 46px;
    padding-top: 6px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 12px; 
    
    .icon {
        fill: $dark-blue;
        margin-right: $margin-base; 
        width: 35px;
        height: 35px;
        display: block;
        margin: 0 auto;
    }  
}
