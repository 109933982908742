.press-subpages {
	display: flex;
	flex-wrap: wrap;
	padding: 3rem 0;

	@include respondto(md) {
		padding: 8rem 0 5rem 0;
	}

	.sub-page {
		margin-bottom: 3rem;
	}
}


.press-social-media-links {
	a {
		display: inline-block;
		padding: 16px 0;
		font-weight: 700;

		.link-text {
			color: $dark-blue;
			margin-left: 0.8rem;
		}
	}
}