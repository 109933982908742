.global-footer {
    background: $dark-blue;
    color: $white;
    padding: $margin-large 0;

    &.stick {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
    }

    .footer-link {
        color: $white;

        &:not(.btn) {
            &:hover, &:focus {
                border-bottom-color: $white;
            }
        }

        &:focus {
            outline: solid 1px $white;
        }

        &:visited {
            color: $white;
            border-bottom: 1px solid $dark-blue;
        }
    }

    p {
        font-size: $font-size-base;
        margin: $margin-xl 0;

        @include respondto(sm) {
            padding-right: $padding-large-vertical * 2;
        }

        @include respondto(md) {
            font-size: $font-size-large;
        }
    }

    .flex-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 $margin-xl;
        width: auto;

        @include respondto(sm) {
            align-items: stretch;
            flex-direction: row;
            margin: 0 auto;
            width: 750px;

            .flex-child {
                flex: 1;
                padding: 0 $padding-large-vertical * 3;

                &:first-child {
                    background-image: linear-gradient(to left, rgba(255, 255, 255, .25), white, rgba(255, 255, 255, .25));
                    background-position: right ($padding-large-vertical * 2);
                    background-repeat: no-repeat;
                    background-size: 3px 92%;
                    padding-right: $padding-large-horizontal
                }

                &:last-child {
                    padding-left: $padding-large-horizontal
                }

                .inline-flex {
                    align-items: flex-start;
                    display: flex;
                    justify-content: space-around;

                    .list-unstyled {
                        &:first-child {
                            padding-left: 20px;
                        }

                        margin-top: $margin-xl;

                        > li {
                            white-space: nowrap;
                            padding: $padding-large-vertical 0
                        }
                    }
                }
            }
        }

        @include respondto(md) {
            .flex-child {
                &:first-child {
                    padding-right: $padding-large-horizontal * 2
                }

                &:last-child {
                    padding-left: $padding-large-horizontal * 2
                }
            }

            width: 970px;
        }

        @include respondto(lg) {
            width: 1170px;
        }

        .flex-child {
            transition: padding .3s;
            width: 100%
        }
    }
}

.footer-link {
    line-height: 1.222222222222222;
    position: relative;

    @include respondto(xs-down) {
        display: block;
        padding: $padding-base-vertical 0;
    }

    @include respondto(sm-down) {
        font-size: $font-size-small;
    }

    .icon {
        fill: $white;
        padding-right: $padding-small-horizontal;

        use:hover {
            border-bottom: 0;
        }
    }
}
#global-footer .footer-link:hover {
    border-bottom-color: $dark-blue;
}#global-footer .footer-link span:hover {
    border-bottom: 1px solid $white;
}
