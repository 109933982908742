﻿// Link
// Link block
// - Link block is link with multiple child elements. The link block itself should have no hover effect. Each child element should be underlined when hovered.
// --------------------------------------------------

a.link-box, a.link-box:link, a.link-box:visited {
    border-bottom: none !important;
    margin-right: 10px;
}

.link, a.link {
    display: flex;
    align-items: center;
    max-width: fit-content;
    color: $dark-blue;

    &:hover {
        color: $link-hover-color;
    }

    &:visited {
        color: $dark-blue;
    }
}