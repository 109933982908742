.author-page {
  .splash {
    background-color: #F5B90080;
  }

  .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000363;

    .title {
      margin-top: 0;
      margin-bottom: 20px;
      color: #000363;
      font-size: 28px;
      font-family: Archivo;
      font-weight: 400;
      line-height: 40px;
      word-wrap: break-word;
    }

    .name {
      margin-top: 20px;
      font-size: 48px;
      text-align: center;

      @media only screen and (min-width: $screen-sm) {
        font-size: 64px;
      }
    }

    img {
      width: 180px;
      height: 180px;
      border-radius: 100px;
      margin-bottom: 20px;

      @media only screen and (min-width: $screen-sm) {
        width: 200px;
        height: 200px;
      }
    }
  }
  &__preamble {
    @include preamble;
    margin-bottom: 30px;

    h2, h3, h4 {
      color: black;
      font-size: 28px;
      font-family: Archivo;
      font-weight: 400;
      line-height: 40px;
      word-wrap: break-word;
    }
  }

  &__body {
    margin-bottom: 40px;

    p {
      color: black;
      font-size: 18px;
      font-family: Archivo;
      font-weight: 400;
      line-height: 27px;
      word-wrap: break-word;
    }
  }


  .info-container {
    max-width: 850px;

    .author-pages {
      margin-bottom: 50px;

      h3 {
        color: #000363;
        font-size: 24px;
        font-family: Archivo;
        font-weight: 700;
        line-height: 38px;
        word-wrap: break-word;

        @media only screen and (min-width: $screen-sm) {
          font-size: 32px;
        }
      }

      ul {
        li {
          @media only screen and (min-width: $screen-sm) {
            line-height: 3;
          }
        }

        a {
          color: #000596;
          font-size: 18px;
          font-family: Archivo;
          font-weight: 700;
          line-height: 27px;
          word-wrap: break-word
        }
      }
    }
  }

  .contact-card {
    background-color: #F5B90080;
    padding: 30px;
    padding-bottom: 30px;

    h3 {
      font-weight: 700;
      margin: 0;
      font-size: 24px;
      color: #000363;
      margin: 0;
      margin-bottom: 12px;

      @media only screen and (min-width: $screen-sm) {
        font-size: 32px;
      }
    }

    .email {
      color: #000363;
      font-size: 18px;
      font-family: Roboto Mono;
      font-weight: 500;
      line-height: 19px;
      word-wrap: break-word;
      border-bottom: none;
      text-decoration: underline;
      height: 30px;

      &::before {
        @include icon($icon-size-medium, $color: $black-blue, $image: url("../images/icons/email.svg"));
      }

      .contact-author {
        margin: 0 0 0 30px;
      }
    }
  }

  .last-updated {
    margin-top: 70px;
    margin-bottom: 30px;
    color: #000363;
    font-size: 16px;
    font-family: Roboto Mono;
    font-weight: 500;
    line-height: 19px;
    word-wrap: break-word
  }

  .btn-show-more {
    background-color: $dark-blue;
    color: white;
    padding: 10px;
  }
}