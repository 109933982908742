//
// Buttons
// --------------------------------------------------
.btn {
    border: 0;
    font-weight: 500;
    line-height: 1.222222222222222;
    transition: background-color $animation-speed-default;
	// todo: padding: $spacing-xs;

    .notifications {
        margin-right: $badge-inline-right-margin;
    }
}

.btn.btn-primary {
	color: $white;
}

.btn.btn-white {
	color: $dark-blue;
	background-color: $white;

	&:hover {
		color: $link-hover-color;
		outline: 1px solid $link-hover-color;
	}
}

.btn.btn-tertiary {
	color: $dark-blue;
}

.btn-primary {
    background-color: $dark-blue;
}

.btn-default {
	background-color: $mercury;
	box-shadow: 0 1px 0 $gray;
	&:hover, &:focus {
		background-color: $alto
	}
	&:active {
		background-color: $silver-chalice;
		box-shadow: 0 1px 0 $gray;
	}
}

// Alternate buttons
// --------------------------------------------------

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-tertiary {
	@include button-variant($dark-blue, $btn-default-bg, $btn-default-bg);
}

.btn--link {
	border-bottom: 1px solid $dark-blue;
	background-color: transparent;
	color: $dark-blue;
	padding: 0;
}

// Reset button (remove background etc)
.btn-clean {
	background: transparent;
	border: none;
	padding: 0;
	-webkit-appearance: none;
}