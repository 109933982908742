$article-list-margin:			30px !default;

// Article list
.article-list {
	@extend .list-unstyled;

	@include respondto(md){
		margin-top: 50px; 
	}

	> li,
	.article-list-item {
		margin: $article-list-margin 0;
	}

	p {
		color: $text-color;
	}
}

.article-list-item {
    a, a:link {
        border: none;
    }
}

.startpage-sidebar .article-list-item .article-list-item-heading {
    margin-top: 0 !important;
}

.startpage-sidebar .article-list-item .article-list-item-meta + .article-list-item-heading {
    margin-top: 5px !important;
    margin-bottom: 5px;
}

.article-list-item-heading {
    font-weight: 700;
    margin-top: 0;
    color: $brand-primary;
    font-size: $font-size-large;
    font-weight: 700;
    line-height: 1.208333333333333;
    margin-bottom: $margin-small;
    margin-top: $margin-base;

    &-underline {
        text-decoration: underline;
    }

    @include respondto(sm) {
        font-size: $font-size-h2;
    }

    @include respondto(md) {
        font-size: $listing-heading-size;
    }
}

.article-list-item-meta {
    @extend .category;
    border-bottom: 2px solid transparent;

    &.tag {
        padding: $padding-small-vertical $padding-small-horizontal;
    }
}

.article-list-item-meta-active {
    span {
        border-bottom: 2px solid $yellow;
    }
}

.article-list-item-text {
    margin: $margin-base 0;
}

.article-list-item-heading.top-header {
    font-size: 28px;

    @include respondto(md) {
        font-size: 36px;
    }

    @include respondto(md) {
        font-size: $font-size-h1;
    }
}

.article-list-item-heading.top-header-news {
    margin: 30px 0 -15px 0;
    padding-left: 15px;
    color: #333;
}

.article-list-item .article-list-item-heading {
    margin-top: 5px;
    margin-bottom: 5px;
}