.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: $spacing-xs;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  @include new-font-sizes;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    padding: 58px 47px;
    border-radius: 5px;
    max-width: 500px;
    word-wrap: break-word;
    overflow: hidden;
    hyphens: auto;

    @media (max-width: $screen-sm) {
      padding: 58px $spacing-xs;
    }
  }

  h1 {
    margin: 0;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 1rem;
    }

    .btn-white {
      border: none;
    }
  }
}