.function-menu {
  .top-menu-container {
    display: flex;
    margin-top: $margin-large;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
    height: 50px;

    li {
      margin-right: $link-list-inline-spacing / 2;
      margin-bottom: .5em;

      &:last-child {
        margin-right: 0;
      }
    }
    
    #search-form{
      margin-right: -10px;
    }
  }

  a {
    border-bottom: 0;
    color: $dark-blue;
    font-size: $font-size-xsmall;
    font-weight: 700;
    line-height: 1.214285714285714;

    &[role="button"] {
      border-color: transparent;
      margin-left: $padding-small-horizontal;
      transition: background-color $animation-speed-default, box-shadow $animation-speed-default;
      color: #fff;

      &:active {
        box-shadow: 0 2px 0 darken(#878787, 15%);
      }

      &:focus {
        outline-offset: 0;
      }
    }
  }

  .btn {
    font-size: $font-size-xsmall;
    font-weight: 700;
    line-height: 1.214285714285714;
  }

  .open {
    .dropdown-menu {
      animation: fadeIn .5s;
    }
  }

  .search-menu {
    &__button {
      background: none;
    }

    &__form-control {
      border-radius: 3px;
      border: 1px solid $black-blue;
      padding: 5px;

      @media screen and (max-width: $screen-md-max) {
        width: 100px;
      }

      @media screen and (max-width: $screen-sm-max) {
        width: 55px;
      }
    }
  }
}

.personal-menu {
  background-color: $diamond-blue;
  border: 1px solid $dark-blue;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
  min-width: 300px;
  padding: $padding-large-vertical $padding-small-horizontal $margin-base;

  .dropdown-title__header {
    margin: 0 $padding-small-horizontal $margin-xs;
  }

  .dropdown-title__link, .dropdown-title__link:link, .dropdown-title__link:visited {
    background-position: center bottom;
    background-size: 100% 1px;
    padding: $padding-base-vertical 0 $padding-large-vertical;
  }

  .dropdown-title__link:hover, .dropdown-title__link:focus {
    background-image: linear-gradient(to bottom, $prussian-blue, $prussian-blue);
    color: $prussian-blue
  }

  .dropdown-link {
    @include link-styles {
      font-size: $font-size-base;
      font-weight: 400;
      line-height: 1.375;
      padding: $padding-base-vertical $padding-base-horizontal;
      &::before {
        height: calc(100% - (12px));
        top: 6px;
        width: 2px
      }
    }
  }

  li {
    margin-bottom: 0
  }
}