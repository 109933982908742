// Icon sizes
$icon-size-xs: 1.2rem;
$icon-size-small: 1.6rem;  
$icon-size-medium: 2.4rem;
$icon-size-large: 3.2rem;  
$icon-size-xl: 4.8rem;    

@mixin pseudo-block {
  content: "";
  display: block;
  position: absolute;
}

@mixin icon-size($size) {
  -webkit-mask-size: $size $size;
  mask-size: $size $size;
  width: $size;
  height: $size;
}

@mixin icon($size, $color: $dark-blue, $image: null) {
  @include pseudo-block;
  @include icon-size($size);
  @include icon-color($color);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  @if $image != null {
    @include icon-image($image);
  }
}

@mixin icon-color($color) {
  background-color: $color;
}

@mixin icon-image($image) {
  -webkit-mask-image: $image;
  mask-image: $image;
}

// Use to set the size of the background plate of an icon.
@mixin icon-plate-size($width, $height) {
  width: $width;
  height: $height;
}

// Use to set the background plate of an icon.
@mixin icon-plate($width, $height, $background-color: transparent, $is-circle: false) {
  @include icon-plate-size($width, $height);
  background-color: $background-color;

  @if $is-circle {
    border-radius: var(--border-radius-round);
  }
}