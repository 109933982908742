// Related-links

/*a*/.related-link {
    @include clearfix;
    @include link-styles {
        border: 1px solid $gray;
        display: block;
        margin: ($font-size-base * $line-height-base) 0 ($font-size-base * $line-height-base) $margin-base;
        aside & {
            margin-bottom: ($font-size-base * $line-height-base);
            @include respondto(sm) {
                margin-top: 0;
            }
        }
        padding: 18px $padding-large-horizontal;
        position: relative;
        &.short {
            border: 0;
            margin: ($font-size-base * $line-height-base) 0 ($font-size-base * $line-height-base) $margin-xs;
            padding: $padding-small-vertical $padding-base-horizontal;
        }
    }
    &::before {
        border-left: 15px solid $yellow;
        bottom: -1px;
        content: " ";
        display: block;
        left: -15px;
        position: absolute;
        top: -1px;
    }
    &.short::before {
        border-left: 5px solid $yellow;
        left: -5px;
    }
    .related-link-image {
        float: right;
        margin: 0 0 3% 3%;
        width: calc(100vw * 0.20);

        @include respondto(sm) {
            margin: 0 0 0 2%;
        }
        
        @include respondto(md) {
            width: calc(100vw * 0.08);
        }
 
    }
    .related-link-heading {
        display: block;
        font-size: $font-size-h2;
        font-weight: 700;
        line-height: $line-height-computed;
        margin-bottom: $margin-small; 
        color: $brand-primary; 
        
        @include respondto(sm) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    .related-link-text {
        color: $mine-shaft; 
        margin-bottom: 0; 
    }
    .read-more {
        color: $mine-shaft
    }
}