.article {
	margin-bottom: $margin-xl
}

.article-heading {
	color: $mine-shaft;
	font-size: 36px;
	line-height: 1.203125;
	margin-top: $margin-base;
	@include respondto(sm){
		font-size: 42px;
	}
	@include respondto(md){
		font-size: $article-heading-size;
	}
}

.article-preamble {
	@include preamble;
}

.article-body {
    perspective: 800px;

    p {
        @include respondto(md){ 
            font-size: $font-size-large; 
        }
    }

    video, iframe {
        @include respondto(xs-down) {
            width: 100%;
        }
    }

    img {
        max-width: 100%;
        height: auto;

        &.img-right,
        &.img-left,
        &.img-center {
            float: none;
            display: block;
            max-width: 100%;
            height: auto;
        }

        &.img-right {
            margin: ($grid-gutter-width / 2) auto;

            @include respondto(sm) {
                float: right;
                margin: 0 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2);
                max-width: 50%;
            }
        }

        &.img-left {
            margin: ($grid-gutter-width / 2) auto;

            @include respondto(sm) {
                float: left;
                margin: 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2) 0;
                max-width: 50%;
            }
        }

        &.img-center {
            margin: ($grid-gutter-width / 2) auto;
        }
    }
}

.article-image {
	margin: $grid-gutter-width auto;
}

.article-category {
	@extend .category
}

// To avoid to complex logic for when video in mainimage is rendered on startpage or not
// this class corresponds to the col-8 class
.square-video {
    width: 66.66667%;
}

.image-alt-text{
    padding-top: 10px;
}