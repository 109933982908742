﻿.plyr {
    margin: 16px 0;
    font-family: $font-family-sans-serif;

    button {
        border-radius: 0%;
    }

    .plyr__progress input[type=range],
    .plyr__volume input[type=range] {
        color: $brand-primary;
    }

    .plyr__control--overlaid {
        background-color: $brand-primary;

        &:hover, &:focus {
            background-color: darken($brand-primary, 12%);
        }
    }

    .plyr__controls__item,
    .plyr__controls__item button {
        &:hover, &:focus {
            background-color: transparent;
        }  
    }
}
