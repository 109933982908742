.site-logo {
    display: block;
    position: absolute;
    max-width: 155px;
    left: 0;
    top: 0;

    @include respondto(sm) {
        left: 15px;
        top: 45px;
    }

    @include respondto(md) {
        max-width: 214px;
        top: 30px;
    }

    @include link-styles {
        border-bottom: 0;
    }

    .error-500 & {
        position: static
    }
}

.site-logo-wrapper {
	width: 200px;
    height: 50px;
    margin: 0 auto;
    cursor: pointer;
}

.site-logo-mobile {
	width: 100%;
	height: 100%; 
    background-image: url(/static/images/logo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 55%;
}

.site-logo-footer {
    height: auto;
    margin: 10px auto;
    max-width: 100px;
    position: relative;

    @include respondto(sm) {
        left: 0;
        top: 0;
        margin: 30px 0 40px 0;
        max-width: 155px;
    }

    @include respondto(lg) {
        max-width: 230px;
    }
}
