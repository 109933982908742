.filterByCategories .btn.btn-filter {
    margin-bottom: $margin-xs;
    margin-top: $margin-xs;
}

.filterByCategories .btn-filter.active {
    background-color: #000596;
    color: white;
}

.filterByCategories .btn:hover {
    background-color: #000596;
    color: white;
}

.filterByCategories .btn-filter {
    color: #000596;
    background-color: #fff;
    border: solid;
    border-color: #000596;
}
