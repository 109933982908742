@mixin border-to-the-side {
    &::before {
        background-color: transparent;
        content: " ";
        display: block;
        height: calc(100% - (#{$padding-large-vertical} * 2));
        left: $margin-xs;
        position: absolute;
        top: $padding-large-vertical;
        transform: translate(0, $top-offset);
        transition: background-color $animation-speed-default, left $animation-speed-default;
        width: $dropdown-link-line-width;
    }
}

@mixin lightning-border-to-the-side {
    &::before {
        background-color: $yellow;
        content: " ";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: $padding-small-vertical;
    }
}