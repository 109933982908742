// Assignment

.assignments-heading {
    font-weight: 700;
    margin-top: 0
}

.assignment {
    @extend .gradient-border-bottom;
    padding-bottom: $margin-base;

    &:last-child {
        margin-bottom: 30px; 
    }
}

.assignment-title {
    font-size: $font-size-large; 
    line-height: 38px;
    margin-top: $margin-large; 

    @include respondto(xs-down){
       margin-bottom: 0; 
    }

    @include respondto(sm){
       font-size: $font-size-h2;
    }

    @include respondto(md){
       font-size: $listing-heading-size; 
    }
}

.assignment-list {
    @extend .list-unstyled;
}

.assignment-list-item {
    color: $emperor
}
