// Breadcrumbs
.row > .breadcrumb-wrapper {
    margin: 0 $margin-base
}

.breadcrumb {
    padding: 15px 0; 
    >li {
        font-size: $font-size-xsmall;
        +li::before {
            color: $dark-blue;
            content: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m335 274c0 3-1 5-3 7l-133 133c-2 2-5 3-7 3-2 0-5-1-7-3l-14-14c-2-2-3-4-3-7 0-2 1-5 3-6l112-113-112-112c-2-2-3-4-3-7 0-2 1-4 3-6l14-14c2-2 5-3 7-3 2 0 5 1 7 3l133 133c2 2 3 4 3 6z" fill="#{$dark-blue}"/></svg>');
            display: inline-block;
            fill: $dark-blue;
            font-size: $font-size-xsmall;
            padding: 0;
            vertical-align: top;
            width: $font-size-small
        }
        @include respondto(sm) {
            &, +li::before {
                font-size: $font-size-base;
            }

        }
        a {
            border-bottom: 0;
            line-height: 1.222222222222222;
        }
        &.active {
            span {
                color: $mine-shaft
            }
        }
    }
}

.main-breadcrumb {
    margin-bottom: 0; 
}
