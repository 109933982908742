// Invoice
.invoice {
    background-color: $wild-sand;
    margin: $margin-xl 0
}
.invoice-content {
    padding: 0 $padding-large-horizontal * 2;
    transition: padding $animation-speed-default;
    @include respondto(sm) {
        padding: 0 15px
    }
}

.invoice-label {
    color: $gray-dark;
    display: block
}

.invoice-title {
    span {
        background-color: $dark-blue;
        color: $wild-sand;
        display: block;
        font-size: $font-size-large;
        padding: $padding-large-horizontal $padding-large-horizontal
    }
    .period {
        display: block;
        font-size: $font-size-small;
        margin-top: $margin-small; 
        font-style: normal;
        @include respondto(sm) {
            float: right;
            margin-top: initial; 
            font-size: $font-size-base;
        }
    }
}

.invoice-summation {
    span {
        display: inline-block;
        font-size: $font-size-small;
        transition: padding $animation-speed-default;
        @include respondto(sm) {
            padding: 0 $padding-large-horizontal $padding-base-vertical
        }
        b {
            font-size: $font-size-base;
            white-space: nowrap
        }
    }
}

.table-as-cols, .invoice-details {
    font-size: $font-size-small;
    .dl-horizontal {
        margin: $margin-small 0;
        dt {
            transition: width $animation-speed-default
        }
        dd {
            margin-bottom: $margin-small;
            transition: margin $animation-speed-default;
            white-space: nowrap
        }
        @include respondto(sm) {
            padding-left: $padding-large-horizontal;
            dt {
                display: block;
                float: none;
                text-align: left;
                width: auto
            }
            dd {
                margin-bottom: $margin-large;
                margin-left: 0
            }
        }
        @include respondto(md) {
            padding-left: $padding-large-horizontal;
            dt {
                float: left;
                width: 145px
            }
            dd {
                margin-bottom: 0;
                margin-left: 150px
            }
        }
        @include respondto(lg) {
            padding-left: $padding-large-horizontal;
            dt {
                text-align: left;
                width: 160px
            }
            dd {
                margin-left: 180px
            }
        }
    }
    .cell {
        margin-bottom: $margin-small;
        margin-top: $margin-small;
    }
    .partial-payment {
        &__highlight {
            color: $cinnabar
        }
    }
}
