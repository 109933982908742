﻿.navigation-page .section-container {
    margin-top: 15px;
}

.standardpage .section-container {
    margin-top: 15px;
}

@media (min-width: 780px) {
    .standardpage .section-block {
        padding-left: 0px;
    }
}

@media (max-width: 780px) {
    .standardpage .section-block {
        padding-left: 0px;
        padding-right: 0px;
    }
}



.section-container {
    margin-top: $margin-xl;
}

.section-container .row {
    display: flex;
    flex-wrap: wrap;
}

.section-container div:last-child {
    padding-bottom: 0px;
}

.section-block {
    display: flex;
    flex-direction: column;
    align-self: stretch;

    @include respondto(sm-down) {
        padding-bottom: $margin-xl;
    }
}

.section-content {
    background-color: #d2e7fd;
    color: #333333;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.section-block img {
    width: 100%;
    object-fit: cover;
}

.section-block p {
    margin: 0px $margin-base $margin-base $margin-base;
    text-align: left;
}

.section-block.clickable-div h2,
.section-block.clickable-div h3,
.section-block.clickable-div h4 {
    margin: $margin-base $margin-base 0px $margin-base;
    text-align: left;
    color: $dark-blue;
}

.section-block h2,
.section-block h3,
.section-block h4 {
    margin: $margin-base $margin-base 0px $margin-base;
    text-align: left;
}

.clickable-div .section-content:active {
    background-color: #e5e5e5;
    cursor: pointer;
}


@media (max-width: 991px) {
    .section-block {
        width: 100%;
    }
}


/* Respect col-sm-8 on NavigationPage */

@media (min-width: 991px) {
    .navigation-page .section-container {
        width: 100%;
        max-width: none;

        & .section-block {
            padding-left: 0px;
        }
    }

    .clickable-div .section-content:hover {
        background-color: #e5e5e5;
        cursor: pointer;
    }
}

@media (max-width: 991px) {
    .navigation-page .section-container .section-block {
        padding-right: 0px;
        padding-left: 0px;
    }
}