h1,
.h1 {
    font-size: $font-size-h1;
}

h2, .h2 {
    line-height: 1.375;
    font-size: $font-size-large;
    @include respondto(md){
        font-size: $font-size-h2;
    }

}

h3, .h3 {
    line-height: 1.35; 
    font-size: $font-size-base;

    @include respondto(md){
        font-size: $font-size-h3;
    }
}

h4,
.h4 {
    font-weight: 400;
    font-size: $font-size-base; 
    line-height: 1.35;
    margin-bottom: (($line-height-computed * 0.5)/ 2); 

    @include respondto(md){
        font-size: $font-size-h3;
    }
}

p {
    margin-bottom: $margin-base;
    font-size: $font-size-small; 
    @include respondto(md){
        font-size: $font-size-base;
    }
}

blockquote {
    border-left: 0;
    color: $dark-blue;
    font-family: $font-family-complementary; 
    font-size: $font-size-base;
    line-height: 1.375;
    font-weight: 700; 
    p {
        font-size: inherit;
        line-height: inherit;
    }
    margin: $margin-large 0;
    padding: ($padding-large-vertical * 2) $line-height-computed;
    position: relative;
    text-align: center;

    @include respondto(md) {
		margin: $margin-xl 0;
	}
}

.font-complementary {
    font-family: $font-family-complementary;
}

.text-blue {
    color: $dark-blue;
}

em,
i,
.italic {
    font-style: italic;
}

$list-indent: 1.8rem;

ul:not([class]) {
    font-size: $font-size-base;
    line-height: 1.888888888888889;
    list-style-type: none;
    padding-left: 0;

    ul {
        
    }

    li {
        position: relative;
        padding-left: $list-indent;
    }

    li::before {
        position: absolute;
        left: 0;
        color: $dark-blue;
        content: "\25A0"; // "\25AA" is a small square
        font-size: $font-size-small;
    }
}

ol:not([class]) {
    counter-reset: section;
    list-style-type: none;
    padding-left: 0;

    li {
        position: relative;
        margin: $margin-base 0;
    }

    li::before {
        margin-top: 1px;
        color: $dark-blue;
        counter-increment: section;
        content: counters(section, ".") "";
        display: inline-block;
        font-size: $font-size-small;
        font-weight: 700;
        margin-right: 0.7rem;
    }

    li li {
        padding-left: $list-indent * 2;
    }

}

.nowrap {
    white-space: nowrap; 
}