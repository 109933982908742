// Login-form
$spinnerSize: 60;

.login-heading {
    text-align: center
}

.bank-id-login {
    margin: ($margin-xl * 2) 0 $margin-xl;
    position: relative;

    &__legend {
        align-items: center;
        border: 0;
        display: flex;
        flex-wrap: nowrap;
        font-size: $font-size-h2;
        font-weight: 700;
        line-height: 1.375;
        margin: $margin-large 0 $margin-xl;

        &-text {
            flex: 1
        }

        &-logo {
            width: 49px
        }
    }

    .form-group,
    .member-login-wrapper,
    .list-inline {
        margin: 0 0 $margin-xl;
    }

    .control-label {
        font-weight: 400;
    }

    .list-inline {
        margin-bottom: $margin-small;
        margin-top: $margin-xl
    }

    &__logging-in {
        margin: $margin-base 0 $margin-xl;
        position: relative;

        p {
            margin: $margin-large 0 $margin-xl;
        }

        &-abort {
            @include list-style();
            margin-top: $margin-base;
            padding: 0;
        }
    }

    &__failed {
        &-abort {
            @include list-style();
            margin-top: $margin-base;
            padding: 0;
        }
    }

    &__spinner,
    .spinner {
        display: block;
        margin: 0 auto $margin-large;
        height: $spinnerSize + px;
        x: 0;
        y: 0;
        width: $spinnerSize + px;
        viewBox: 0 0 $spinnerSize $spinnerSize;

        circle {
            fill: transparent;
            stroke: $dark-blue;
            stroke-dasharray: (3.14 * $spinnerSize);
            stroke-linecap: round;
            stroke-width: 6;
            transform-origin: center center 0;
            animation: spinner 2.5s linear infinite;
        }
    }
}

.qr-code-wrapper {
    background: $wild-sand;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    img.qr-code {
        max-width: 250px;
        margin: 10px auto;
    }

    .bankid-logo {
        margin: 30px auto 0;
        width: 30px;
    }

    #btn-cancel-bankid {
        margin-bottom: 10px;
    }

    .message {
        position: relative;
        margin: 0px 30px;
    }
}

.bank-id-info {
    background: $wild-sand;
    padding: 2rem;

    > p:last-child {
        margin-bottom: 0;
    }
}

@keyframes spinner {
    0% {
        stroke-dashoffset: (0.66 * $spinnerSize);
        transform: rotate(0deg);
    }

    50% {
        stroke-dashoffset: (3.14 * $spinnerSize);
        transform: rotate(720deg);
    }

    100% {
        stroke-dashoffset: (0.66 * $spinnerSize);
        transform: rotate(1080deg);
    }
}

