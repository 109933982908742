.startpage-banner {
    margin-bottom: -1px;

    img {
        width: 100%;
        height: auto;
    }
}

.splash {
    background-color: $clear-blue;
    color: #fff;
    position: relative;
    padding: 5px 15px;
    overflow: hidden;

    @include respondto(md) {
        padding: 5px 40px;
    }

    @include respondto(lg) {
        padding: 40px 140px;
    }

    .splash-heading {
        font-size: $font-size-xlarge;
        margin: 0;

        @include respondto(md) {
            font-size: $font-size-h1;
        }
    }

    .splash-content {
        max-width: 860px;
        margin: auto;

        @include respondto(md-down) {
            margin: 4%;
        }

        p {
            font-size: $font-size-base;
            line-height: 1.375;
            color: #fff;
            margin-bottom: 0;

            @include respondto(md) {
                font-size: $font-size-large;
            }
        }
        /*a {
            color: #fff;
            border-bottom: 0;
        }*/

        .arrows {
            color: $orange;
            vertical-align: text-top;
        }
    }

    .splash-btn {
        padding: 10px;
        margin-top: 32px;
        line-height: 1.375;
        font-weight: 700;

        @include respondto(md) {
            font-size: $font-size-large;
        }
    }
}