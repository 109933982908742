// Membership
.membership,
.information {
    @extend .generic-form;
    .membership-form-footer {
        margin-top: $margin-xl
    }
}

.modal-body {
    margin-top: 63px;
    padding-bottom: $margin-xl;
    padding-left: $margin-xl;
    padding-right: $margin-xl;
    padding-top: 0;
    h1 {
        margin-top: 0
    }
}
.close {
    margin: $margin-base;
    opacity: 1;
    transition: opacity $animation-speed-default
}

input:required:valid ~ .validation-message,
select:required:valid ~ .validation-message {
    display: none
}

input:required:invalid ~ .validation-message,
select:required:invalid ~ .validation-message {
    @extend .alert;
    @extend .alert-warning;
    display: block;
    margin-top: $margin-small
}

.information {
    fieldset {
        dd {
            margin-bottom: $margin-base;
        }
    }
    fieldset:not(:first-child) {
        legend {
            margin-top: $margin-small
        }
    }
}

.membership .personal-info {
    background-color: lightblue;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.membership .formGroupsStudent {
    margin-bottom: 20px;
}

.membership .cancel-button {
    margin-top: 15px;
}

.bank-id-auth {
    margin: 20px 0 30px;
    position: relative;
}