.text-block {
  &__content {
    h2, h3, h4, p {
      max-width: 72rem;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .text-block__content {
      h2, h3, h4 {
        margin-bottom: $spacing-xxxs !important;
        margin-top: 0 !important;
        max-width: 60rem;
      }

      p {
        margin: 0;
        max-width: 60rem;
      }
    }
  }

  &--blue-background-color {
    background-color: $black-blue;

    .text-block__content {
      padding: $spacing-large $spacing-large;

      @media (max-width: $screen-sm) {
        padding: $spacing-large $spacing-xs;
      }

      h2, h3, h4 {
        color: $yellow;
      }

      p {
        color: $white;
      }
    }
  }

  &--blue-heading-color {
    h2, h3, h4 {
      color: $dark-blue;
    }
  }
}