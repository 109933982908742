.my-handbook-progress-bar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-medium;
  width: auto;
  padding: 1rem 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $gray;
    transform: translateY(-50%);
    z-index: 1;
  }

  &__step {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: $gray;
    z-index: 2;
  }

  &__step.completed {
    background-color: $white;
    border: 3px solid $black;
  }

  &__step.active {
    background-color: $clear-blue;
    border: 1.8px solid $black;
  }

  @media (max-width: $screen-sm) {
    gap: $spacing-small;

    &__step {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @media (max-width: $screen-xs) {
    gap: 1.5rem;
    &__step {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
}