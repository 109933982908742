.global-nav-wrapper {
    @include clearfix;
    border-bottom: $padding-xs-horizontal solid $dark-blue;
    .startpage {
        & {
            border: 0;
        }
    }
}

.global-nav {
    background-color: transparent;
    border-color: transparent;
    margin-bottom: 0;
    position: relative;
    top: $padding-xs-horizontal;

    .navbar-nav > li > a {
        border-bottom: $padding-xs-horizontal solid transparent;
        color: $dark-blue;
        font-size: $font-size-xsmall;
        font-weight: 700;
        transition: border-color $animation-speed-default;

        @include respondto(md) {
            font-size: $font-size-base
        }
    }

    .navbar-nav > li > a:hover,
    .navbar-nav > li > a:focus {
        border-bottom-color: $yellow;
        color: $link-hover-color;
    }

    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus,
    .navbar-nav > .open > a,
    .navbar-nav > .open > a:hover,
    .navbar-nav > .open > a:focus {
        background-color: transparent;
        border-bottom-color: $yellow;
        color: $dark-blue;
    }
}