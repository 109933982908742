.search-page {
  color: $dark-blue-text;
  min-height: 800px;
  margin-bottom: 50px;

  h1 {
    font-size: 64px;
    margin-top: 25px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 15px;

    svg {
      fill: $black-blue;
      width: 44px;
      height: 44px;
    }

    @media screen and (max-width: $screen-xs-max) {
      font-size: 48px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  h2 {
    font-size: 48px;

    @media screen and (max-width: $screen-xs-max) {
      font-size: 36px;
    }
  }

  &__intro-text {
    margin-bottom: 25px;
    font-size: 28px;
  }

  .search-input-container {
    #search-form-page {
      display: flex;

      .search-field {
        margin-right: 15px;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: $screen-xs-max) {
          width: 100%;
        }

        svg {
          position: absolute;
          left: 0.5em;
          pointer-events: none;
          width: 1em;
          height: 1em;
          fill: #000;
        }

        .form-control {
          width: 500px;
          height: 47px;
          border: 1px solid $dark-blue;
          padding: 6px 30px;

          @media screen and (max-width: $screen-xs-max) {
            width: 100%;
          }
        }
      }
    }

    .btn {
      font-weight: 700;
      height: 47px;
      width: 58px;
    }
  }

  .search-result-container {
    flex-direction: column;
    display: flex;
    row-gap: 10px;
    max-width: 750px;
    list-style: none;
    margin-top: $spacing-small;
    padding: 0;

    @media screen and (min-width: $screen-sm) {
      margin-top: $spacing-large;;
    }

    .search-result {
      &__date {
        font-weight: 700;

        @media screen and (max-width: $screen-xs-max) {
          font-size: 14px;
        }
      }

      h3 {
        font-size: 32px;
        margin: 5px 0;

        @media screen and (max-width: $screen-xs-max) {
          font-size: 20px;
        }
      }

      &__info-text {
        font-size: 20px;
        margin: 15px 0;
        color: $black;

        @media screen and (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }
    }
  }

  .btn-show-more {
    background-color: $dark-blue;
    color: white;
    padding: 10px;
  }
}