.author-card {
  margin-top: 4.8rem;
  margin-bottom: 7.2rem;
  background-color: #FADC80;

  &:hover {
    background-color: #E6E6E6;
    cursor: pointer;
  }

  h2 {
    margin-top: 0;
    font-size: 32px;
    font-weight: 700;
    color: $black-blue;
  }

  &__heading-desktop {
    display: none;
  }

  &__heading-mobile {
    margin-bottom: 0;
  }

  @media only screen and (min-width: $screen-sm) {
    &__heading-mobile {
        display: none;
    }

    &__heading-desktop {
      display: block;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__details {
    font-size: 18px;
    line-height: 19px;
    font-weight: 700;
    color: $dark-blue;
    display: flex;
    flex-direction: column;
    row-gap: $spacing-xxs;
    font-family: $font-family-complementary;
    
    h3 {
      font-family: $font-family-sans-serif;
    }

    a {
      border-bottom: none;

      &:visited {
        color: $dark-blue;
      }
    }
  }

  &__icon {
    margin-top: $spacing-small;
    &::before {
      @include icon(3.6rem, $color: $dark-blue, $image: url("../images/icons/arrow.svg"));
      position: relative;
    }
  }
  
  &__information {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-xs;
    column-gap: $spacing-medium;
    padding: $spacing-medium;

    @media only screen and (min-width: $screen-sm) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__image {
    width: 150px;
    height: 150px;
    border-radius: 100px;
  }
}