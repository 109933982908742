﻿.press-image {
    object-fit:cover;
}

.press-images-container {

    > a:not(:last-child) {
        border-bottom:none;
        margin-right:1rem;
    }
}