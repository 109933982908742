.load-more {
    margin: $margin-xl 0;
    .btn {
        padding-bottom: $padding-large-vertical;
        padding-top: $padding-large-vertical
    }
    .btn ~ span {
        display: inline-block;
        padding: $padding-large-vertical $padding-base-horizontal;
        vertical-align: middle
    }
}
