.my-handbook-coursepage {
  position: relative;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  background-color: $section-blue;
  padding: $spacing-xl 0 5rem;
  @include new-font-sizes;

  @media (max-width: $screen-sm) {
    padding-top: $spacing-small;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
    max-width: 114rem;
    margin-right: auto;
    margin-left: auto;
    padding: $spacing-large $spacing-large $spacing-xl $spacing-large;
    background-color: $white;

    @media (max-width: $screen-sm) {
      width: 100%;
      padding: $spacing-small $spacing-xs $spacing-small $spacing-xs;
    }
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }

  a {
    font-size: clamp(14px, 2vw, 20px);
    text-decoration: none;
    border-bottom: none;
    color: $dark-blue;
    border-bottom: 1px solid $dark-blue;

    &:not(span):visited {
      color: $dark-blue;
    }
  }

  &__link-close  {
    &::after {
      content: "\2715";
      margin-left: 8px;
      color: $dark-blue;
      font-weight: bold;

      @media (max-width: $screen-sm) {
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }

  &__heading {
    margin: 0;

    @media (max-width: $screen-sm) {
      font-size: 32px;
    }
  }

  &__sub-heading {
    font-size: 24px;
    line-height: 0.3;

    @media (max-width: $screen-sm) {
      font-size: 20px;
    }
  }

  .article-preamble {
      margin: 0;
  }

  a.link:hover {
    color: $link-hover-color;
  }

  .accordion {
    .accordion__content {
      padding-left: 32rem;

      @media (max-width: $screen-sm) {
        padding-left: 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: $spacing-small;
      padding-top: $spacing-medium;
      padding-bottom: $spacing-xxs;
      border: none;
      border-bottom: 1px solid $mercury;
    }

    &__toggle {
      display: none;

      &:checked + .accordion__header::after {
        transform: translateY(-50%) rotate(-135deg);
        top: .6rem;
      }

      &:checked + .accordion__header + .accordion__content {
        max-height: 100rem;
        padding-left: 32rem;
        opacity: 1;

        @media (max-width: $screen-sm) {
          padding-left: 0;
        }
      }
    }

    &__header {
      color: $dark-blue;
      cursor: pointer;
      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h3 {
        font-size: 26px;
        border-bottom: 1px solid $dark-blue;
        margin: 0;
        display: inline-block;
      }

      &::after {
        content: '';
        position: relative;
        top: .6rem;
        margin-left: $spacing-xxs;
        border: solid $dark-blue;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: .5rem;
        transform: translateY(-50%) rotate(45deg);
        transition: transform 0.3s ease;
      }
    }

    &__content {
      max-height: 0;
      overflow: hidden;
      padding-left: $spacing-medium;
      opacity: 0;
      transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out, opacity 0.3s ease;

      @media (max-width: $screen-sm) {
        padding-left: 0;
      }

      p {
        font-size: $font-size-large;
        margin: 0;
        padding: $spacing-xxs 0;

        &:first-child {
          padding-top: 0;
        }
      }

      .link {
        display: flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }

      a {
        color: $black;
        text-decoration: none;
        border-bottom: 0;
      }
    }

    &__link-text {
      margin-left: $spacing-xxxs;
    }
  }
}