// Course-listing-row
.course-listing-row-wrapper {
    @extend .gradient-border-bottom;
    padding-bottom: $padding-large-horizontal * 2
}

.course-listing {
    &__heading {
        font-size: $font-size-large;
        line-height: 1.1875;

        @include respondto(sm){
            font-size: $font-size-h2;
        }

        @include respondto(md){
            font-size: $article-heading-size / 2;
        }
    }

    &__description {
        font-size: $font-size-large;
        margin-bottom: $margin-base
    }

    &__location-and-time {
        font-size: $font-size-base;
        margin: $margin-base 0 $margin-large;
    }

    &__apply {
        &_end-date {
            display: inline-block;
            vertical-align: middle
        }
    }

    &__error {
        margin-top: $margin-base
    }

    &__cancel-course {
        &--modal-body {
            padding: $padding-large-vertical $padding-large-horizontal
        }

        &--body {
            margin-top: 0 !important;
            padding-top: $padding-large-horizontal !important;
            .btn {
                margin-bottom: $margin-small
            }
        }

        &--modal.in {
           .modal-dialog {
               transform: translate(0, 100%)
           } 
        }
    }
}

.course-listing-row {
    .btn {
        font-size: $font-size-xsmall;
        line-height: 1.214285714285714;
        margin-right: $margin-base
    }
    .alert-info {
        margin-bottom: 0; 
    }
}

.course-listing__applied_info {
    font-weight: 700; 
    font-size: $font-size-base; 
    margin-right: $margin-base;
    vertical-align: bottom;
    display: inline-block;
}

.course-listing__applied_info + .alert {
    margin-top: $margin-base; 
}