/// <reference path="../application-variables" />

.fullpage-ad {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;

    background-color: $brand-primary;
    color: $white;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
}

.fullpage-ad-edit-mode {
    max-height: 800px;
}

.fullpage-ad-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding-large-vertical * 2 $padding-large-horizontal;
    background-color: $brand-primary;
    color: $white;
    z-index: $zindex-navbar;
    
    &:hover, &:focus {
        color: $white;
        background-color: darken($brand-primary, 10%);
    }
}

.fullpage-ad-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.fullpage-ad-text {
    text-align: center;
    transform: translateY(-10%);
    text-shadow: 2px 2px 5px rgba(0, 5, 150, .7);

    p {
        font-size: 34px;
        font-weight: $headings-font-weight;
        line-height: 1.3;
        color: $white;

        @include respondto(sm) {
            font-size: 64px;
        }

        @include respondto(md) {
            font-size: 80px;
        }
    }
}

.fullpage-ad-bg {
    background-size: cover;
    background-position: top center;
}