.person-list-block {
	&__grid-container {
		display: grid;
		gap: 16px;
		width: 100%;
		max-width: 1200px;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}

	.vcard {
		background-color: white;
		overflow: hidden;
		text-align: center;

		@include respondto(sm) {
			text-align: left;
		}

		img {
			width: 100%;
			object-fit: cover;
		}

		&__heading {
			margin-top: $spacing-small;
		}

		&__title {
			display: block;
			font-weight: bold;
			color: $vcard-title-color;
			margin-bottom: $line-height-computed;

			@include respondto(sm) {
				margin-bottom: ($line-height-computed / 2);
			}
		}
	}
}