﻿.colored-paragraph {
    background-color: #d2e7fd;
}

.richtextbox-block {
    margin-top: $margin-xl;
    padding-left: $margin-base;
    padding-right: $margin-base;
    
}

div.colored-paragraph :first-child {
    margin-top: 15px;
}

div.colored-paragraph :last-child {
    margin-bottom: 15px;
}

/* Respect col-sm-8 on NavigationPage */
.col-sm-8 .rte-container {
    width: 100%;
    max-width: none;
}

.colored-paragraph .colored-text {
    width: 100%;
}

.section-container .rte-container {
    padding-left: 0px;
}

.section-container .richtextbox-block {
    width: 100%;
}

.standardpage .rte-container {
    padding-left: 0px;
    padding-right: 0px;
}

.standardpage .richtextbox-block {
    width: 100%;
}

