.completed-course-block {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-small;
  margin-bottom: $spacing-xxl;

  @include new-font-sizes;

  a {
    font-size: 20px;

    @media (max-width: $screen-sm) {
      font-size: $pf-font-size-small;
    }
  }

  .link {
    display: flex;
    align-items: center;
    max-width: fit-content;

    &--arrow-left {
      @include arrow-left($dark-blue, 2.5px, .4rem, .5rem);
    }

    &--arrow-right {
      @include arrow-right($dark-blue, 2.5px, .4rem, .5rem);
    }
  }

  .main-header {
    margin-top: $spacing-small;
  }

  .trophy {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin-top: $spacing-xl;
    margin-bottom: $spacing-medium;

    &__image {
      width: 28rem;
    }

    &__text {
      text-align: center;
    }
  }

  &__startpage-link {
    display: inline-block;
    align-self: flex-start;
    margin-top: $spacing-small;
  }

  .link-collection {
    display: flex;
    flex-direction: column;
    gap: $spacing-medium;
    max-width: fit-content;
    margin-top: $spacing-large;

    h3 {
      margin: 0;
    }

    &__startpage-link {
      display: flex;
      flex-direction: column;
      gap: $spacing-xxs;
    }

    &__collected {
      display: flex;
      flex-direction: column;
      gap: $spacing-xxs;
    }
  }
}