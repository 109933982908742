.share-page {
    transform-origin: left;
    a {
        @include link-styles {
            border: 0;
            display: inline-block;
            font-size: $font-size-small;
            margin-right: $margin-small;
            transition: fill $animation-speed-default;
            &:last-of-type() {
                margin-right: 0
            }
        }
        @include link-interactive-styles {
            .icon {
                fill: $link-hover-color; 
            }
        }
    }
    .icon {
        @include respondto(sm-down){
            width: 40px;
            height: 40px;
        }
    }

    h2 {
        @include respondto(sm-down){
            font-size: $font-size-base; 
        }
    }
}
