@mixin preamble {
  font-size: $font-size-large;
  line-height: 1.428571428571429;
  margin-bottom: $margin-large;

  @include respondto(sm) {
    font-size: $font-size-h2;
  }

  @include respondto(md) {
    font-size: $font-size-xlarge;
    margin-bottom: $margin-xl;
  }
}

@mixin detailed-text {
  font-family: "Roboto Mono";
  font-size: clamp(16px, 1.5vw, 18px);
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}