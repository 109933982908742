﻿//
// Variables
// --------------------------------------------------

// Auto generated variables from settings file

@import "application-colors";
@import "application-margins";
@import "application-paths";
@import "application-icons";

// Colors

$black: #000;
$white: #fff;
$purple: #8040BF;

$gray-base: $black;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: #878787;
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee

$dark-blue-text: $black-blue;
$brand-primary: $dark-blue;
$brand-secondary: $regal-blue;
$brand-success: $wattle;
$brand-info: $deep-cerulean;
$brand-warning: $yellow;
$brand-danger: $red-damask;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: #fff;
//** Global text color on `<body>`.
$text-color: $gray-dark;

//** Global textual link color.
$link-color: $dark-blue;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($dark-blue, 10%) !default;
$link-active-color: darken($dark-blue, 15%) !default !default;
//** Link hover decoration.
$link-hover-decoration: none;
$menu-link-hover-color: darken($dark-blue, 10%);


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Archivo', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-complementary: 'Roboto Mono', Menlo, Monaco, Consolas, "Courier New", monospace;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 18px;
$font-size-large: ceil(($font-size-base * 1.111111111111111)); // 20px
$font-size-small: ceil(($font-size-base * 0.8888888888888889)); // 16px
$font-size-xlarge: floor(($font-size-base * 1.555555555555556)); // 28px
$font-size-xsmall: floor(($font-size-base * 0.7777777777777778)); // 14px
$font-size-splash: $font-size-base * 1.777777777777778;

$font-size-h1: floor(($font-size-base * 2.666666666666667)); // 48px
$font-size-h2: ceil(($font-size-base * 1.333333333333333)); // 24px
$font-size-h3: ceil(($font-size-base * 1.111111111111111)); // 20px
$font-size-h4: ceil(($font-size-base * 1.111111111111111)); // 20px

$article-heading-size: ceil($font-size-base * 3.555555555555556); // 64px
$listing-heading-size: 32px;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.388888888888889;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // 25px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-color: inherit;

// New font sizes
$pf-font-size-xxs: 14px;
$pf-font-size-xs: 16px;
$pf-font-size-small: 18px;
$pf-font-size-medium: clamp(20px, 2vw, 22px);
$pf-font-size-large:  clamp(22px, 3vw, 26px);
$pf-font-size-xl:  clamp(26px, 4vw, 32px);
$pf-font-size-xxl: clamp(36px, 5vw, 48px);

$pf-line-height-xs: 1.2;
$pf-line-height-small: 1.4;
$pf-line-height-medium: 1.5;
$pf-line-height-large: 1.6;

// New common spacing variables
$spacing-xxxs: 8px;
$spacing-xxs: 12px;
$spacing-xs: 16px;
$spacing-small: 24px;
$spacing-medium: 32px;
$spacing-large: 48px;
$spacing-xl: 72px;
$spacing-xxl: 120px;
$spacing-xxxl: 160px;

//== Components

// 

//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

// TODO: Map these to margins

$padding-base-vertical: 6px;
$padding-base-horizontal: 12px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 16px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: (19px / $font-size-small);

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//== Animation
$animation-speed-default: .25s;
$animation-function-quintic: cubic-bezier(.86,0,.07,1);

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

//** Default background color used for all tables.
$table-bg: transparent;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: #ddd;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal;

$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-secondary-color: #fff;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-border: darken($btn-primary-bg, 5%);

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);

$btn-link-disabled-color: $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-large;
$btn-border-radius-small: $border-radius-small;


//== Forms
//
//##

//** `<input>` background color
$input-bg: #fff;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: $gray;
//** `<input>` border color
$input-border: #828282;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

//** Border color for inputs on focus
$input-border-focus: #66afe9;

//** Placeholder text color
$input-color-placeholder: #666;

//** Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-control` height
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

//** `.form-group` margin
$form-group-margin-bottom: 15px;

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;


//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #fff;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

//== Tabs
$nav-tabs-border-color: #ddd;

$nav-tabs-link-hover-border-color: $gray-lighter;

$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: #ddd;

$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

//== Pagination
//
//##

$pagination-color: $link-color;
$pagination-bg: #fff;
$pagination-border: #ddd;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: #ddd;

$pagination-active-color: #fff;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #ddd;


//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: $gray-lighter;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: ceil(($font-size-base * 1.5));
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5));


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: $mine-shaft;
$state-info-bg: $mercury;
$state-info-border: $mercury;

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: #000;
$tooltip-opacity: .9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;


//== Popovers
//
//##

//** Popover body background color
$popover-bg: #fff;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: rgba(0,0,0,.2);
//** Popover fallback border color
$popover-fallback-border-color: #ccc;

//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%);

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05);
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%);


//== Labels
//
//##

//** Default label background color
$label-default-bg: $gray-light;
//** Primary label background color
$label-primary-bg: $brand-primary;
//** Secondary label background color
$label-secondary-bg: $brand-secondary !default;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: $wild-sand;
//** Secondary label text color
$label-secondary-color: $mercury !default;
//** Default text color of a linked label
$label-link-hover-color: #fff;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 15px;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: #fff;
//** Modal content border color
$modal-content-border-color: rgba(0,0,0,.2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

//** Modal backdrop background color
$modal-backdrop-bg: #000;
//** Modal backdrop opacity
$modal-backdrop-opacity: .5;
//** Modal header border color
$modal-header-border-color: #e5e5e5;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: bold;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: #fff;
//** Breadcrumb text color
$breadcrumb-color: #ccc;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: ">";

//== Close
//
//##

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint;
//** Horizontal line color.
$hr-border: $gray-lighter;

$focused-element-outline: solid 1px $brand-primary !default;

$selected-text-color: $white !default;
$selected-text-bg: $brand-primary !default;

//== Forms
//

$styled-checkbox-size: 30px !default;
$styled-checkbox-label-margin: 10px !default;
$styled-checkbox-default-bg: $white !default;
$styled-checkbox-default-border: 2px solid $dark-blue !default;
$styled-checkbox-checked-default-bg: $white !default;
$styled-checkbox-checked-default-border: 2px solid $dark-blue !default;
$styled-checkbox-primary-bg: $white !default;
$styled-checkbox-primary-border: 2px solid $brand-primary !default;
$styled-checkbox-checked-primary-bg: $brand-primary !default;
$styled-checkbox-checked-primary-border: 2px solid $brand-primary !default;
$styled-checkbox-secondary-bg: $white !default;
$styled-checkbox-secondary-border: 2px solid $brand-secondary !default;
$styled-checkbox-checked-secondary-bg: $brand-secondary !default;
$styled-checkbox-checked-secondary-border: 2px solid $brand-secondary !default;
$styled-checkbox-default-icon-color: $mine-shaft !default;
$styled-checkbox-default-icon: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m426 378c0 7-3 14-8 19l-39 39c-5 5-12 8-20 8-7 0-14-3-19-8l-84-84-84 84c-5 5-12 8-19 8-8 0-15-3-20-8l-39-39c-5-5-8-12-8-19 0-8 3-14 8-20l84-84-84-84c-5-5-8-12-8-19 0-8 3-14 8-20l39-38c5-6 12-8 20-8 7 0 14 2 19 8l84 84 84-84c5-6 12-8 19-8 8 0 15 2 20 8l39 38c5 6 8 12 8 20 0 7-3 14-8 19l-84 84 84 84c5 6 8 12 8 20z" fill="#{$styled-checkbox-default-icon-color}"/></svg>') !default;
$styled-radio-default-icon: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m475 256c0 40-9 77-29 110-20 34-46 60-80 80-33 20-70 29-110 29-40 0-77-9-110-29-34-20-60-46-80-80-20-33-29-70-29-110 0-40 9-77 29-110 20-34 46-60 80-80 33-20 70-29 110-29 40 0 77 9 110 29 34 20 60 46 80 80 20 33 29 70 29 110z" fill="#{$styled-checkbox-default-icon-color}"/></svg>') !default;
$styled-checkbox-primary-icon-color: $wild-sand !default;
$styled-checkbox-primary-icon: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m256 0c-141 0-256 115-256 256c0 141 115 256 256 256c141 0 256-115 256-256c0-141-115-256-256-256z m0 472c-119 0-216-97-216-216c0-119 97-216 216-216c119 0 216 97 216 216c0 119-97 216-216 216z" fill="#{$styled-checkbox-primary-icon-color}"/></svg>') !default;
$styled-checkbox-secondary-icon-color: $mercury !default;
$styled-checkbox-secondary-icon: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m477 162c0 7-2 14-8 19l-206 207-39 39c-6 5-12 8-20 8-7 0-14-3-19-8l-142-142c-6-6-8-12-8-20 0-7 2-14 8-19l38-39c6-5 12-8 20-8 7 0 14 3 19 8l84 84 188-188c5-5 12-8 19-8 8 0 14 3 20 8l38 39c6 6 8 12 8 20z" fill="#{$styled-checkbox-secondary-icon-color}"/></svg>') !default;

//== Tree menu
//

$nav-tree-border-radius: $border-radius-small !default;
$nav-tree-link-color: $white !default;
$nav-tree-link-bg: $brand-primary !default;
$nav-tree-link-hover-color: $white !default;
$nav-tree-link-hover-bg: lighten($nav-tree-link-bg, 10%) !default;
$nav-tree-active-link-color: $white !default;
$nav-tree-active-link-bg: $brand-primary !default;
$nav-tree-active-link-hover-color: $white !default;
$nav-tree-active-link-hover-bg: lighten($nav-tree-active-link-bg, 10%) !default;

$nav-tree-sublevel-border-radius: $border-radius-small !default;
$nav-tree-sublevel-link-color: $brand-primary !default;
$nav-tree-sublevel-link-bg: $gray-light !default;
$nav-tree-sublevel-link-hover-color: $brand-primary !default;
$nav-tree-sublevel-link-hover-bg: lighten($nav-tree-sublevel-link-bg, 10%) !default;
$nav-tree-sublevel-active-link-color: $black !default;
$nav-tree-sublevel-active-link-bg: $gray-light !default;
$nav-tree-sublevel-active-link-hover-color: $black !default;
$nav-tree-sublevel-active-link-hover-bg: lighten($nav-tree-sublevel-active-link-bg, 10%) !default;

$nav-tree-link-spacing-y: 0 !default;
$nav-tree-link-border: solid 1px #ccc !default;
$nav-tree-link-padding: 15px 20px !default;
$nav-sublevel-indent: 20px !default;


//== Off canvas menu

$off-canvas-menu-width: 420px !default;
$off-canvas-menu-width-sm: 280px !default;
$off-canvas-menu-offset: 60px !default;
$off-canvas-inner-padding: 0 !default;
$off-canvas-content-bg: $wild-sand !default;
$off-canvas-backdrop-bg: $prussian-blue !default;
$off-canvas-backdrop-opacity: .75 !default;
$off-canvas-backdrop-hover-opacity: .55 !default;


//== Button list

$btn-list-margin-right: .5em !default;
$btn-list-margin-bottom: .5em !default;


//== Icon link
$icon-link-margin: .25rem !default;
$icon-link-prefix: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m335 274c0 3-1 5-3 7l-133 133c-2 2-5 3-7 3-2 0-5-1-7-3l-14-14c-2-2-3-4-3-7 0-2 1-5 3-6l112-113-112-112c-2-2-3-4-3-7 0-2 1-4 3-6l14-14c2-2 5-3 7-3 2 0 5 1 7 3l133 133c2 2 3 4 3 6z" fill="#{$dark-blue}"/></svg>') !default;

//== Card

$card-spacer-x: 20px !default;
$card-spacer-y: 20px !default;
$card-block-padding: 20px !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius-base !default;
$card-border-color: transparent !default;
$card-border-radius-inner: $border-radius-small !default;

$card-default-background: transparent !default;
$card-cap-color: $text-color !default;
$card-cap-bg: $gray-light !default;


//== Vcard
$vcard-heading-font-size: $font-size-large !default;
$vcard-heading-font-family: $headings-font-family !default;
$vcard-heading-color: $headings-color !default;
$vcard-title-color: $text-color !default;
$vcard-font-size: $font-size-base !default;
$vcard-img-margin: $grid-gutter-width !default;
$vcard-img-width: 100px !default;