//
// Labels
// --------------------------------------------------

.label-secondary {
	color: $label-secondary-color;
	@include label-variant($label-secondary-bg);
}

.label-success, .label-warning {
	color: $mine-shaft
}
