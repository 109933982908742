// Dropdown-title
.dropdown-title {
    &__link, &__link:link, &__link:visited {
        background-image: linear-gradient(to bottom, $dark-blue, $dark-blue);
        background-size: calc(-#{$padding-large-horizontal * 2} - -100%) 1px;
        background-repeat: no-repeat;
        background-position: $padding-large-horizontal bottom;
        border-bottom: 0;
        color: $dark-blue;
        display: block;
        font-size: $font-size-small;
        font-weight: 700;
        line-height: 1.375;
        margin: 0;
        padding: $padding-base-horizontal $padding-large-horizontal;
        //@include transition(background-image .25s, color .25s)
    }

    &__link:hover, &__link:active {
        background-image: linear-gradient(to bottom, $link-hover-color, $link-hover-color);
        color: $link-hover-color;
    }
}


.dropdown-heading {
    padding-left: 16px;
}
