@media print {
	body {
		margin: 10pt 20pt;
	}

	a,
	a:link,
	a:visited {
		border-bottom: 0;
	}

	.function-menu,
	.global-nav-wrapper,
	.share-page,
	.main-breadcrumb li:first-of-type,
	.load-more {
		display: none;
	}

	.global-header {
		min-height: 100px;
	}

	.category,
	.article-category,
	.article-list-item-meta {
		font-style: italic;
		padding: 5px 0;
	}

	.site-logo::after,
	.article-list-item > a:last-child::after {
		display: none;
	}

	.global-footer {
		border-top: 1px solid #878787;
		text-align: center;
	}

	.global-footer .site-logo,
	.global-footer .list-unstyled {
		display: none;
	}

	.article-body p a::after {
		font-family: 10pt;
		font-weight: 700;
	}
}
