@mixin new-font-sizes {
	h1 {
		font-size: $pf-font-size-xxl;
	}

	h2 {
		font-size: $pf-font-size-xl;
	}

	h3 {
		font-size: $pf-font-size-large;
	}
}