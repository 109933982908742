.region-page {
  margin-bottom: $spacing-xl;
  display: flex;
  flex-direction: column;
  gap: $spacing-small;

  /* TODO: Remove font-sizes on new site */
  h1 {
    font-size: clamp(36px, 4vw, 48px);
  }

  h2 {
    margin-top: $margin-base;
    font-size: clamp(26px, 2.5vw, 32px);
  }

  h3 {
    font-size: clamp(22px, 2vw, 26px);
  }

  p {
    font-size: clamp(18px, 1.5vw, 20px);
  }
  
  h1, h2, h3, p {
    margin: 0;
  }

  .upper-content {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
    }

    &__preamble {
      font-size: $spacing-small;
      margin: 0;
    }

    &__text {
      display: flex;
      max-width: 70rem;
      gap: $spacing-xs;
      flex-direction: column;

      a {
        margin: $spacing-xxs 0;
      }
    }
    
    &__link {
      display: flex;
      align-items: center;
      column-gap: $spacing-xxs;

      .icon-new--user::before {
        @include icon-size(2rem);
        margin: auto;
      }

      @media screen and (max-width: $screen-sm) {
        .icon-new--email {
          align-self: flex-start;
          padding-top: $spacing-xxs;
          padding-right: $spacing-small
        }
      }
    }

    &__map {
      height: 100%;
      width: 100%;
      max-width: 20rem;
      margin: 0 auto;

      @media screen and (max-width: $screen-md) {
        position: static;
        display: flex;
        justify-content: center;
        height: 32rem;
        margin: $spacing-small auto 0;
      }
    }
  }

  .contact-cards {
    &__heading {
       margin: $spacing-xs 0 $spacing-small;
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-small;

      @media screen and (max-width: $screen-md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $screen-xs) {
        grid-template-columns: 1fr;
      }
    }

    .author-card {
      padding: $spacing-medium $spacing-xs;
      margin: 0 !important;

      @media screen and (max-width: $screen-xs) {
        padding: $spacing-xs;
      }

      &__information {
        padding: 0;
      }

      &__title {
        font-size: $font-size-base;
        font-weight: 500;
      }

      &__mail {
        display: flex;
        align-items: center;
        font-size: $font-size-base;
        font-weight: 500;

        img {
          padding-right: $spacing-xxxs;
        }
      }

      &__mail-text {
        font-size: $font-size-base;
        font-weight: 500;
      }
    }
  }
}