.region-map-block {
  display: flex;
  flex-direction: row;
  gap: $spacing-xxl;
  justify-content: center;

  @media screen and (max-width: $screen-sm) {
    gap: $spacing-xl;
    justify-content: flex-start;
    align-items: center;
  }

  #map {
    flex: 1;
    max-width: 26%;
    margin-top: $spacing-xxs;

    svg {
      width: 27.5rem;
      height: 63.9rem;

      @media screen and (max-width: $screen-sm) {
        width: 13.7rem;
        height: 32rem;
      }
    }
  }

  .region-list {
    flex: 1;
    max-width: 20%;

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      padding: 10px;
      color: $dark-blue;
      margin-bottom: 1px solid $dark-blue;
      list-style: none;
      cursor: pointer;

      &::before {
        content: '';
      }
    }
  }

  .region {
    fill: $diamond-blue;
    stroke: $dark-blue;
    transition: fill 0.3s;
    pointer-events: auto;
    cursor: pointer;

    @media screen and (max-width: $screen-sm) {
      cursor: default;
    }
    
    &:hover {
      fill: $dark-blue;
    }

    &--highlight {
      fill: $dark-blue;
    }
  }
}