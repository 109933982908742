//
// Typography
// --------------------------------------------------

$sizeList: sm md lg;

/* Internet explorer applies focus to elements that cannot be focues, like divs, etc */
a,
input,
textarea,
button {
    &:focus {
	    outline: $focused-element-outline;
    }
}

@mixin list-style {
	border-bottom: 1px solid $link-color;
	transition: border-bottom .3s, color .3s;
}

a {
  @include link-styles {
    @include list-style();
  }

  &:not(span) {
    &:hover, &:focus {
      border-bottom-color: $link-hover-color;
      color: $link-hover-color;
      transition: color 0.3s, border-bottom-color 0.3s; // Ensure transition duration is set
    }

    &:active {
      color: $link-active-color;
    }
  }

  &.read-more-with-icon {
    border-bottom: 0;

    &:hover, &:focus {
      .icon {
        fill: $link-hover-color;
        transition: fill 0.3s; // Ensure transition duration is set
      }
    }

    &:active {
      .icon {
        fill: $prussian-blue;
      }
    }
  }

  &.read-more {
    border-bottom: 0;

    &::after {
      content: " >";
    }
  }

  &.read-more-svg {
    border-bottom: 0;

    &::after {
      content: $icon-link-prefix;
      display: inline-block;
      height: $font-size-base * $line-height-base - 2;
      vertical-align: middle;
      width: $font-size-base;
    }
  }
}

// Selected text
// -------------------------

::selection {
	color: $selected-text-color;
	background-color: $selected-text-bg; /* WebKit/Blink Browsers */
}

::-moz-selection {
	color: $selected-text-color;
	background-color: $selected-text-bg; /* Gecko Browsers */
}

// Contextual colors
// -------------------------

@include text-emphasis-variant('.text-secondary', $brand-secondary);

@include text-emphasis-variant('.text-color', $text-color);

@include text-emphasis-variant('.text-black', $black);

@include text-emphasis-variant('.text-white', $white);


// Contextual backgrounds
// -------------------------

// Inverted text colors if needed
/*.bg-primary {
  color: #fff;
}*/

@include bg-variant('.bg-secondary', $brand-secondary);

.bg-secondary {
	color: $wild-sand;
}

// Font helper classes
// -------------------------

.font-xsmall {
	font-size: $font-size-small - 2;
}

.font-small {
	font-size: $font-size-small;
}

.font-base {
	font-size: $font-size-base;
}

.font-large {
	font-size: $font-size-large;
}

.font-sans-serif {
	font-family: $font-family-sans-serif;
}

.font-serif {
	font-family: $font-family-serif;
}

.body-text {
    color: $text-color !important;
}

@each $viewportSize in $sizeList {
	@include respondto($viewportSize) {

		.text-#{$viewportSize}-left   { text-align: left !important; }
		.text-#{$viewportSize}-right  { text-align: right !important; }
		.text-#{$viewportSize}-center { text-align: center !important; }

	}
}